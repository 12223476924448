define('oath-ui-components/components/oa-group-creation/remove/component', ['exports', 'oath-ui-components/components/oa-group-creation/remove/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    actions: {
      remove() {
        this.data.removeObject(this.item);
      }
    }
  });
});