define('oath-ui-components/helpers/merge', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.merge = merge;
  function merge(params) {
    return params.reduce((accumulator, value) => Ember.assign(accumulator, value), {});
  }

  exports.default = Ember.Helper.helper(merge);
});