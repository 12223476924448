define('oath-ui-components/services/oa-asset', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    cache: Ember.computed(() => Ember.Object.create()).readOnly(),

    load: (0, _emberConcurrency.task)(function* (cacheKey, loader) {
      const cache = this.get('cache');

      if (cache.get(cacheKey)) {
        return cache.get(cacheKey);
      }

      const deferred = Ember.RSVP.defer();

      cache.set(cacheKey, deferred.promise);

      for (let retries = 3; retries > 0; retries -= 1) {
        try {
          deferred.resolve((yield* loader()));
          return deferred.promise;
        } catch (err) {
          if (!retries) {
            deferred.reject(err);
            cache.set(cacheKey, null);
          }
        }
      }

      return deferred.promise;
    })
  });
});