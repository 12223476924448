define('ember-microstates/helpers/object', ['exports', 'ember-microstates', 'ember-microstates/utils/assign', 'ember-microstates/utils/object-utils', 'ember-microstates/utils/is-primitive'], function (exports, _emberMicrostates, _assign, _objectUtils, _isPrimitive) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberMicrostates.MicroState.extend({

    initialValueFor([object = {}]) {
      if ((0, _isPrimitive.default)(object)) {
        throw new Error(`Object microstate expects a non primitive value in its constructor, but received ${object}`);
      }
      return object;
    },

    /**
     * Make all of the object properties explicitly enumerable in the template
     * 
     * Handlebars helpers like `each-in` use `Object.keys` to iterate object properties. `Object.keys` returns
     * only properties must be owned by the object. This hook will assign each value's property onto the object,
     * to ensure that it can be retrieved with `Object.keys`.
     */
    handlebarsValueFor(object, value) {
      return (0, _objectUtils.reduceObject)(value, function (result, name, value) {
        return (0, _assign.default)(result, {
          [name]: value
        });
      }, object);
    },

    transitions: {
      delete(current, target) {
        return (0, _objectUtils.reduceObject)(current, function (result, name, value) {
          if (target === name) {
            return result;
          } else {
            return (0, _assign.default)(result, {
              [name]: value
            });
          }
        });
      },
      assign(current, object = {}) {
        return (0, _assign.default)({}, current, object);
      },
      put(current, property, value) {
        return (0, _assign.default)({}, current, {
          [property]: value
        });
      }
    }
  });
});