define('oath-ui-components/components/oa-datatables/row-actions/component', ['exports', 'oath-ui-components/components/oa-datatables/row-actions/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    links: Ember.computed('columnActions.@each', 'row', function () {
      return this.get('columnActions').map(action => this.mappedAction(action));
    }),

    mappedAction(action) {
      return {
        title: action.title,
        link: action.link ? action.link(this.get('row')) : '',
        callback: action.callback
      };
    },

    actions: {
      perform(action, event) {
        action.callback(this.get('row'), this.get('api'), event);
      }
    }
  });
});