define('oath-ui-components/components/oa-notifications--business-bar/component', ['exports', 'oath-ui-components/components/oa-notifications/base-notification-component', 'oath-ui-components/components/oa-notifications--business-bar/template'], function (exports, _baseNotificationComponent, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseNotificationComponent.default.extend({
    layout: _template.default,
    classNames: ['oa-notifications--business-bar']
  });
});