define('oath-ui-components/components/oa-toggle-base/component', ['exports', 'oath-ui-components/components/oa-toggle-base/template', 'oath-ui-components/components/oa-input-base/component'], function (exports, _template, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: 'oa-toggle-base',
    classNameBindings: ['value:oa-toggle-base--on:oa-toggle-base--off', 'isFocused:oa-toggle-base--focus', 'isBlurred:oa-toggle-base--blur', 'disabled:oa-toggle-base--disabled:oa-toggle-base--enabled'],
    attributeBindings: 'title',
    layout: _template.default,

    /**
     * Value for the control
     *
     * @public
     * @property value
     */
    value: false,

    /**
     * @attribute disabled
     */
    disabled: false,

    /**
     * @protected
     * @property inputElement
     */
    inputElement: Ember.computed(function () {
      return this.element.querySelector('input[type=checkbox]');
    }).readOnly(),

    didRender() {
      this._super(...arguments); // eslint-disable-line prefer-rest-params

      this.set('inputElement.checked', this.get('value'));
      this.set('inputElement.disabled', this.get('disabled'));
    },

    sendEventAction(actionName) {
      return this._super(actionName, this.get('inputElement.checked'));
    }
  }).reopenClass({
    positionalParams: ['value']
  });
});