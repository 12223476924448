define('oath-ui-components/mixins/styleable', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function prepareRule(sheet, selector) {
    const rule = Array.from(sheet.cssRules).reverse().find(item => item.selectorText === selector);

    if (rule) {
      return rule;
    }

    sheet.insertRule(`${selector} {}`, sheet.cssRules.length);

    return prepareRule(sheet, selector);
  }

  function serializeStyleTag(sheet) {
    if (sheet.ownerNode) {
      const flatten = (0, _lodash.reduce)(sheet.rules, (sum, rule) => `${sum}${rule.cssText}`, '');
      // eslint-disable-next-line no-param-reassign
      sheet.ownerNode.textContent = flatten;
    }
  }

  exports.default = Ember.Mixin.create({
    sheet: Ember.computed(() => Ember.$('<style>').appendTo(document.head).prop('sheet')).readOnly(),

    style(selector, properties) {
      const { environment } = Ember.getOwner(this).resolveRegistration('config:environment');
      const sheet = this.get('sheet');
      const rule = prepareRule(sheet, selector);

      Object.keys(properties).forEach(key => {
        let value = properties[key];

        if (typeof value === 'function') {
          value = value.call(this);
        }

        if (value === null) {
          rule.style.removeProperty(key);
        } else {
          const match = value.match(/(.+?)\s*(!important)?$/);
          const important = match.pop() && 'important';
          const property = match.pop();

          rule.style.setProperty(key, property, important || null);
        }
      });

      if (environment === 'test') {
        Ember.run.scheduleOnce('afterRender', this, serializeStyleTag, sheet);
      }
    },

    willDestroyElement(...args) {
      this._super(...args);
      Ember.$(this.get('sheet.ownerNode')).remove();
    }
  });
});