define('oath-ui-components/components/oa-grid/tr--each/component', ['exports', 'oath-ui-components/utils/run', 'oath-ui-components/components/oa-grid/tr--each/template'], function (exports, _run, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-tr--each',
    layout: _template.default,

    /**
     * @attribute {} model
     */
    model: null,

    /**
     * @attribute {} key
     */
    key: null,

    rowHeight: null,
    rowMeasure: Ember.computed(function () {
      return this.$('.oa-tr--measure');
    }).readOnly(),

    initialRenderCount: Ember.computed('model.[]', function () {
      const rowHeight = this.get('rowHeight');
      const length = this.get('model.length');
      const height = this.$().closest('.ember-application').height();
      const total = length * rowHeight;

      if (total > height) {
        return Math.min(length, Math.floor(height / rowHeight));
      }
      return length;
    }).readOnly(),

    block: Ember.computed(function () {
      return this.$().closest('[data-table-block]');
    }).readOnly(),

    didInsertElement(...args) {
      this._super(...args);

      Ember.run.schedule('afterRender', this, function () {
        this.set('rowHeight', this.get('rowMeasure').outerHeight(true));
      });
    },

    updateBlockHeight: (0, _run.observerOnceIn)('afterRender', 'model.length', 'rowHeight', function () {
      const length = this.get('model.length');
      const rowHeight = this.get('rowHeight');

      if (rowHeight !== null) {
        this.get('block').css({
          height: rowHeight * length
        });
      }
    })

  }).reopenClass({
    positionalParams: ['model']
  });
});