define('ember-microstates/initializers/microstates', ['exports', 'ember-microstates/helpers/boolean', 'ember-microstates/helpers/select', 'ember-microstates/helpers/list', 'ember-microstates/helpers/number', 'ember-microstates/helpers/string', 'ember-microstates/helpers/object'], function (exports, _boolean, _select, _list, _number, _string, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.register('helper:Boolean', _boolean.default);
    application.register('helper:Select', _select.default);
    application.register('helper:List', _list.default);
    application.register('helper:Number', _number.default);
    application.register('helper:String', _string.default);
    application.register('helper:Object', _object.default);
  }

  exports.default = {
    name: 'microstates',
    initialize
  };
});