/* eslint-disable */
(function () {
  function vendorModule() {
    return {
      default: self.numbro,
      __esModule: true,
    };
  }

  define('numbro', [], vendorModule);
}());
