define('oath-ui-components/components/oa-grid/th/component', ['exports', 'oath-ui-components/components/oa-grid/th/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-th',
    layout: _template.default,

    attributeBindings: ['width:data-column-width', 'column:data-column-id', 'children:data-column-children'],

    /**
     * @attribute {} width
     */
    width: null,

    /**
     * @attribute {} column
     */
    column: null,

    /**
     * @attribute {} children
     */
    children: 0,

    /**
     * @attribute {} content
     */
    content: null,

    didInsertElement(...args) {
      this._super(...args);

      const children = this.get('children');

      const { TEXT_NODE } = document;

      // TODO do this in compile
      Ember.$(this.element.parentNode.childNodes).each(function () {
        if (this.nodeType === TEXT_NODE) {
          this.data = this.data.trim();
        }
      });

      if (!children) {
        this.$().on('resize', (evt, { size: { width } }) => {
          const [left, right] = this.$().attr('data-padding').split(',').map(Number);

          Ember.run(this, this.set, 'width', `${width + left + right}px`);
        });
      }
    }
  }).reopenClass({
    positionalParams: ['content']
  });
});