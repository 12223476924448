define('ember-microstates/helpers/number', ['exports', 'ember-microstates'], function (exports, _emberMicrostates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberMicrostates.MicroState.extend({

    initialValueFor([value = 0]) {
      return Number(value);
    },

    prototypeFor(value) {
      let wrapped = new Number(value);

      Object.defineProperties(wrapped, {
        toString: {
          value() {
            return String(value);
          }
        },
        valueOf: {
          value() {
            return Number(value);
          }
        }
      });

      return wrapped;
    },

    transitions: {
      add(current, amount) {
        return current + amount;
      },
      subtract(current, amount) {
        return current - amount;
      },
      increment(current) {
        return current + 1;
      },
      decrement(current) {
        return current - 1;
      },
      divide(current, value) {
        return current / value;
      },
      multiply(current, value) {
        return current * value;
      }
    }
  });
});