define('oath-ui-components/components/oa-shopping-cart/footer/component', ['exports', 'oath-ui-components/components/oa-shopping-cart/footer/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['oa-shopping-cart__footer'],
    layout: _template.default
  });
});