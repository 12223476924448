define('oath-ui-components/components/oa-notification-log/component', ['exports', 'oath-ui-components/components/oa-notification-log/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-notification-log'],
    clearAllLabel: 'Clear All',
    notificationsEmptyLabel: 'You have no new notifications',

    tabs: null,

    isLogEmpty: Ember.computed.empty('selectedNotifications'),

    selectedNotifications: Ember.computed('notifications.log.[]', 'selectedTab', function () {
      const notificationType = this.get('selectedTab.type');
      const notificationLog = this.get('notifications.log');

      return Ember.isEmpty(notificationType) ? notificationLog : notificationLog.filterBy('messageType', notificationType);
    }),

    selectedTab: Ember.computed('tabs', function () {
      return (this.get('tabs') || []).find(tab => tab.selected === true);
    }),

    actions: {
      clearLog() {
        this.get('notifications').clearLog();
      }
    }
  });
});