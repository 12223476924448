define('oath-ui-components/components/oa-list-builder/add/component', ['exports', 'oath-ui-components/components/oa-list-builder/add/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-list-builder__add'],

    customAction: null,
    disabled: false,
    tooltipText: '',

    actions: {
      add(item) {
        const customAction = this.get('customAction');

        if (customAction) {
          customAction(item);
        } else {
          this.get('list').pushObject(item);
          this.set('item', Ember.Object.create({}));
        }
      }
    }
  });
});