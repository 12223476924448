define('oath-ui-components/computed/raf', ['exports', 'ember-concurrency', 'ember-concurrency/utils'], function (exports, _emberConcurrency, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = raf;


  class WaitForAnimationFrame {
    // eslint-disable-next-line class-methods-use-this
    [_utils.yieldableSymbol](taskInstance, resumeIndex) {
      const cancelToken = requestAnimationFrame(time => {
        taskInstance.proceed(resumeIndex, _utils.YIELDABLE_CONTINUE, time);
      });

      return function unsubscribe() {
        cancelAnimationFrame(cancelToken);
      };
    }
  }

  // eslint-disable-next-line object-curly-newline
  function raf({ start, stop, update, frames = 60 }) {
    return (0, _emberConcurrency.task)(function* (...args) {
      while (true) {
        const started = start.apply(this, args);

        const result = started && started[Symbol.iterator] && typeof started === 'object' ? yield* started : yield started;

        let last = null;
        let count = 0;

        while (count < frames) {
          const stopped = stop.call(this, result);
          const current = stopped && stopped[Symbol.iterator] && typeof stopped === 'object' ? yield* stopped : yield stopped;

          if (Ember.isEqual(current, last)) {
            count += 1;
          } else if (update) {
            const updated = update.call(this, result, current);
            count = (updated && updated[Symbol.iterator] && typeof updated === 'object' ? yield* updated : yield updated) || 0;
          } else {
            count = 0;
          }

          last = current;

          yield new WaitForAnimationFrame();
        }
      }
    }).restartable();
  }
});