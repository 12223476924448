define('oath-ui-components/components/oa-tabs--vertical/component', ['exports', 'oath-ui-components/components/oa-tabs--vertical/template', 'oath-ui-components/components/oa-tabs--vertical/event-bus'], function (exports, _template, _eventBus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['oa-tabs--vertical'],
    classNameBindings: ['highlights::oa-tabs--vertical--no-highlights', 'collapse:oa-tabs--vertical--collapse'],
    collapseToggle: false,
    collapse: false,
    highlights: true,
    eventbus: null,
    model: null,
    layout: _template.default,

    init(...args) {
      this._super(...args);

      const eventbus = new _eventBus.default();

      this.set('eventbus', eventbus);

      eventbus.on('tab-change', this, this.onTabChange);
    },

    onTabChange(tab, model) {
      Ember.set(model, 'active', true);

      this.sendAction('on-tab-change', model);
    },

    willDestroyElement(...args) {
      this._super(...args);

      this.get('eventbus').off('tab-change', this, this.onTabChange);
    }
  });
});