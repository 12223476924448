define('oath-ui-components/helpers/format', ['exports', 'numbro', 'moment'], function (exports, _numbro, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numbroFormatCurry = numbroFormatCurry;
  exports.format = format;


  const mCtor = _moment.default.fn.constructor;
  const dCtor = _moment.default.duration.fn.constructor;

  const EMPTY = {
    toString() {
      return '--';
    },
    isEqual(comparator) {
      return comparator === '--';
    }
  };

  function unknown(toUpperCase, num, fn) {
    if (Ember.isEmpty(num) || !Number.isFinite(Number(num))) {
      return EMPTY;
    }
    const result = fn();
    if (toUpperCase && typeof result === 'string') {
      return result.toUpperCase();
    }
    return result;
  }

  function numbroFormatCurry(fmt, num, timezone) {
    const toUpperCase = fmt.includes('a');

    return unknown(toUpperCase, num, () => {
      if (fmt.includes('%')) {
        // eslint-disable-next-line no-param-reassign
        num /= 100;
      }
      if (typeof this.currency !== 'undefined') {
        if (this.currency && typeof this.currency.get('format') === 'function') {
          return this.currency.get('format')((0, _numbro.default)(num).format(fmt));
        }

        return (0, _numbro.default)(num).format(`$${fmt}`);
      }

      if (num === EMPTY) {
        return EMPTY;
      }

      if (timezone) {
        num.tz(timezone);
      }

      if (num instanceof mCtor) {
        return num.format(fmt);
      }

      if (num instanceof dCtor) {
        return num[fmt]();
      }

      if (typeof num === 'number' || !Number.isNaN(num)) {
        return (0, _numbro.default)(Number(num)).format(fmt);
      }

      Ember.assert(`Unsupported type ${num} for formatting`);
      return undefined;
    });
  }

  function format(params, hash) {
    const curried = numbroFormatCurry.bind(hash, params[0]);

    if (params.length > 1) {
      return curried(params[1], params[2]);
    }

    return curried;
  }

  exports.default = Ember.Helper.helper(format);
});