define('oath-ui-components/components/oa-notifications--log-item/component', ['exports', 'oath-ui-components/components/oa-notifications--passive/component', 'oath-ui-components/components/oa-notifications--passive/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,

    actions: {
      dismiss() {
        // TODO: Find a way to trigger fade out animation on every DOM element destruction
        this.$().addClass('hide').one('webkitAnimationEnd oanimationend msAnimationEnd animationend', () => {
          this.get('notifications').removeFromLog(this.get('notification'));
        });
      }
    }
  });
});