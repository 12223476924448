define('oath-ui-components/components/oa-dropdown/content/item/component', ['exports', 'oath-ui-components/components/oa-dropdown/content/item/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    isSelected: false,

    /**
     * This is a noop can that can be overriden to invoke own behaviour
     * in a controller that is using the dropdown component
     * @method
    */
    onSelect() {},

    /**
     * Label is used to give a name to an item that is not the actual value of
     * the item. If no label is provided, the value will be used instead.
     *
     * Example: The following will read as 'helloWorld', instead of 'fooBar'.
     *
     *   {{content.item item.value label=item.label}}
     *
     *  item: [
     *  {
     *     id: 1,
     *     value: 'fooBar',
     *     label: 'helloWorld'
     *   }
     * }
     *
     *
     *  @attribute label
     */
    label: '',
    actions: {
      select(value, label, icon) {
        this.sendAction('select', value, label, icon);
        this.get('onSelect')();
      }
    }
  }).reopenClass({
    positionalParams: ['value']
  });
});