define('oath-ui-components/components/oa-global-navigation/user-menu/component', ['exports', 'oath-ui-components/components/oa-global-navigation/user-menu/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['user-menu'],
    layout: _template.default,
    userEmail: 'Username@email.com',
    icon: 'user-fill'
  });
});