define('oath-ui-components/components/oa-rightrail/block/component', ['exports', 'oath-ui-components/components/oa-rightrail/block/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['oa-rightrail__block'],
    layout: _template.default
  });
});