define('oath-ui-components/components/oa-group-creation/clear-all/component', ['exports', 'oath-ui-components/components/oa-group-creation/clear-all/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    actions: {
      clearAll() {
        this.data.clear();
      }
    }
  });
});