define('oath-ui-components/components/oa-range-slider/component', ['exports', 'numbro', 'oath-ui-components/components/oa-range-slider/template'], function (exports, _numbro, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const NUM_STEPS = 1000;

  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: 'oa-range-slider',

    showInput: true,
    min: null,
    max: null,
    step: null,
    values: [null, null],
    format: '0a',

    startDisabled: Ember.computed.empty('values.0'),

    endDisabled: Ember.computed.empty('values.1'),

    roundValueByStep(val) {
      const step = this.get('step');
      return step ? Math.round(val / step) * step : val;
    },

    updateRanges(ratio, isMovingStart) {
      const { min, max } = this.getProperties('min', 'max');

      if (ratio >= 0 && ratio <= 1) {
        let newVal = this.roundValueByStep(min + (max - min) * ratio);
        let start = this.get('rangeStart');
        let end = this.get('rangeEnd');

        newVal = Number((0, _numbro.default)(newVal).format(this.get('format')));
        if (isMovingStart) {
          start = newVal;
        } else {
          end = newVal;
        }
        if (start > end) {
          [start, end] = [end, start];
        }

        this.setProperties({
          errorStart: null,
          errorEnd: null,
          rangeStart: start,
          rangeEnd: end
        });

        this.sendAction('on-range-change', [start, end]);
      }
    },

    didReceiveAttrs(...args) {
      this._super(...args);
      const {
        min = 0, max, step, values
      } = this.getProperties('min', 'max', 'step', 'values');

      this.setProperties({
        rangeStart: Ember.isEmpty(values[0]) ? min : values[0],
        rangeEnd: Ember.isEmpty(values[1]) ? max : values[1],
        step: step || (max - min) / NUM_STEPS
      });
    },

    indicators: Ember.computed('min', 'max', 'rangeStart', 'rangeEnd', function () {
      const {
        min, max, format
      } = this.getProperties('min', 'max', 'format');

      const indicators = [];
      let numIndicators = max - min;
      // Ensure we have at least 4 indicators
      while (Number.isInteger(numIndicators / 10) && numIndicators > 30) {
        numIndicators /= 10;
      }

      for (let i = 0; i <= numIndicators; i += 1) {
        const val = min + (max - min) * i / numIndicators;
        indicators.push({
          style: Ember.String.htmlSafe(`left: ${i * 100 / numIndicators}%`),
          active: val >= this.get('rangeStart') && val <= this.get('rangeEnd')
        });
      }
      indicators[0].val = (0, _numbro.default)(min).format(format);
      indicators[Math.round(numIndicators / 2)].val = (0, _numbro.default)(min + (max - min) / 2).format(format);
      indicators[numIndicators].val = (0, _numbro.default)(max).format(format);

      return indicators;
    }),

    areaStyle: Ember.computed('min', 'max', 'rangeStart', 'rangeEnd', function () {
      const {
        min, max, rangeStart, rangeEnd
      } = this.getProperties('min', 'max', 'rangeStart', 'rangeEnd');
      const width = (rangeEnd - rangeStart) * 100 / (max - min);
      const left = (rangeStart - min) * 100 / (max - min);
      return Ember.String.htmlSafe(`left: ${left.toFixed(2)}%; width: ${width.toFixed(2)}%`);
    }),

    handler0Style: Ember.computed('min', 'max', 'rangeStart', function () {
      const {
        min, max, rangeStart
      } = this.getProperties('min', 'max', 'rangeStart');
      const left = (rangeStart - min) * 100 / (max - min);

      return Ember.String.htmlSafe(`left: ${left.toFixed(2)}%`);
    }),

    handler1Style: Ember.computed('min', 'max', 'rangeEnd', function () {
      const {
        min, max, rangeEnd
      } = this.getProperties('min', 'max', 'rangeEnd');
      const left = (rangeEnd - min) * 100 / (max - min);

      return Ember.String.htmlSafe(`left: ${left.toFixed(2)}%`);
    }),

    actions: {
      setRanges(event) {
        const $ele = this.$(event.currentTarget);

        if (this.get('dragging') || !$ele.hasClass('rail') && !$ele.hasClass('active')) {
          // skip if the handler is not active, eg. disabled
          return false;
        }

        const $rail = this.$('.rail');
        const railLeft = $rail.offset().left;
        const railWidth = $rail.width();
        const ratio = (event.clientX - railLeft) / railWidth;
        const {
          min, max
        } = this.getProperties('min', 'max');

        const value = min + (max - min) * ratio;

        let isMovingStart = true;

        if (!this.get('startDisabled') && Math.abs(this.get('rangeEnd')) === min) {
          isMovingStart = false;
        }

        if (this.get('startDisabled') || // range start disabled, only allow move range end handler
        !this.get('endDisabled') && Math.abs(value - this.get('rangeStart')) > Math.abs(value - this.get('rangeEnd')) // curr value close to range end
        ) {
            isMovingStart = false;
          }

        this.updateRanges(ratio, isMovingStart);

        const $handler = isMovingStart ? this.$('.rail-marker.start') : this.$('.rail-marker.end');
        const $document = this.$(document);

        const mouseMove = ({ clientX }) => {
          const pct = (clientX - railLeft) / railWidth;
          this.updateRanges(pct, isMovingStart);
        };

        const mouseUp = () => {
          $document.off('mousemove', mouseMove);
          $document.off('mouseup', mouseUp);

          $handler.removeClass('dragging');

          this.set('dragging', false);
        };

        $handler.addClass('dragging');

        this.set('dragging', true);

        $document.on('mousemove', mouseMove);
        $document.on('mouseup', mouseUp);

        return false;
      },

      changeRangeValue(name, { target: { value } }) {
        const val = Number(value);
        const {
          min,
          max,
          rangeStart,
          rangeEnd
        } = this.getProperties('min', 'max', 'rangeStart', 'rangeEnd');
        let errorMsg = '';

        if (name === 'Start' && val > rangeEnd) {
          errorMsg = `The value cannot be more than ${rangeEnd}`;
        } else if (name === 'End' && val < rangeStart) {
          errorMsg = `The value cannot be less than ${rangeStart}`;
        } else if (val < min) {
          errorMsg = `The value cannot be less than ${min}`;
        } else if (val > max) {
          errorMsg = `The value cannot be more than ${max}`;
        }

        this.set(`error${name}`, errorMsg);

        if (!errorMsg) {
          this.set(`range${name}`, val);
          this.sendAction('on-range-change', [this.get('rangeStart'), this.get('rangeEnd')]);
        }
      },
      resetInputValue() {
        // clean up error state of inputs
        this.$('.input-container input.start').val(this.get('rangeStart'));
        this.$('.input-container input.end').val(this.get('rangeEnd'));
        this.setProperties({
          errorStart: null,
          errorEnd: null
        });
      }
    }
  });
});