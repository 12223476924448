define('oath-ui-components/components/oa-file/component', ['exports', 'oath-ui-components/components/oa-file/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const File = Ember.ObjectProxy.extend({
    content: null,

    readAsPromise(file, method) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener('load', ({ target: { result: data } }) => {
          resolve(data);
        });

        reader.addEventListener('error', reject);

        reader[method](file);
      });
    },

    basename: Ember.computed('content.name', function () {
      const sections = this.get('content.name').split('.');

      if (sections.length > 1) {
        return sections.slice(0, -1).join('.');
      }

      return sections[0];
    }).readOnly(),

    extname: Ember.computed('content.name', function () {
      const splitted = this.get('content.name').split('.');

      if (splitted.length > 1) {
        return `.${splitted.slice(-1)}`.toLowerCase();
      }

      return null;
    }).readOnly(),

    isImageFile: Ember.computed('extname', function () {
      return ['.png', '.jpeg', '.jpg'].includes(this.get('extname'));
    }).readOnly(),

    isTextFile: Ember.computed('content.name', function () {
      return ['.txt', '.csv', '.tsv', '.xml', '.svg'].includes(this.get('extname'));
    }).readOnly(),

    asDataURL: Ember.computed('content', function () {
      return this.readAsPromise(this.get('content'), 'readAsDataURL');
    }).readOnly(),

    asArrayBuffer: Ember.computed(function () {
      return this.readAsPromise(this.get('content'), 'readAsArrayBuffer');
    }).readOnly(),

    asText: Ember.computed(function () {
      return this.readAsPromise(this.get('content'), 'readAsText');
    }).readOnly(),

    asBinaryString: Ember.computed(function () {
      return this.readAsPromise(this.get('content'), 'readAsBinaryString');
    }).readOnly()
  });

  const FileCollection = Ember.ArrayProxy.extend({
    ajax: Ember.inject.service(),

    content: null,

    formData: Ember.computed('content.[]', function () {
      const form = new FormData();

      this.forEach(file => {
        form.append(file.get('name'), file.get('content'));
      });

      return form;
    }).readOnly(),

    uploadTo(url) {
      return this.get('ajax').post(url, {
        data: this.get('formData'),
        processData: false
      });
    }
  });

  exports.default = Ember.Component.extend({
    classNames: 'oa-file',
    classNameBindings: 'isDragOver:oa-file--drag-over',
    layout: _template.default,

    inputElement: Ember.computed(() => Ember.$('<input type="file" multiple>').get(0)).readOnly(),

    dragLeave(evt) {
      evt.preventDefault();

      this.set('isDragOver', false);
    },

    dragOver(evt) {
      evt.preventDefault();

      this.set('isDragOver', true);
    },

    drop(evt) {
      evt.stopPropagation();
      evt.preventDefault();

      this.set('isDragOver', false);

      this.handleFiles(evt.dataTransfer.files);
    },

    change(evt) {
      this.handleFiles(evt.target.files);
    },

    handleFiles(files) {
      const owner = Ember.getOwner(this).ownerInjection();

      /**
       * @event on-change
       */
      return this.sendAction('on-change', FileCollection.create(owner, {
        content: Array.from(files).map(content => File.create(owner, { content }))
      }));
    },

    actions: {
      pick() {
        this.get('inputElement').click();
      }
    }
  });
});