define('oath-ui-components/components/oa-range-selector--dropdown/range/component', ['exports', 'oath-ui-components/components/oa-range-selector--dropdown/range/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-range-selector--dropdown--row'],
    singleMode: false,
    all: false,
    disabled: false,
    range: null,
    label: Ember.computed.alias('range.label'),
    options: Ember.computed.alias('range.options'),
    checked: false,
    events: null,

    init(...args) {
      this._super(...args);

      if (this.get('options').some(option => Ember.get(option, 'checked'))) {
        this.set('checked', true);
      }

      this.get('events').on('change', this.recalculate.bind(this));

      this.normalize();

      this.recalculate();
    },

    normalize() {
      this.updateOptions();
    },

    lower: Ember.computed('options', function () {
      const options = Ember.A(this.get('options'));

      const first = options.find(item => Ember.get(item, 'checked'));

      return first || options.get('firstObject');
    }),

    upper: Ember.computed('options', function () {
      const options = Ember.A(this.get('options'));

      // clone because .reverse() mutates original array
      const reversed = options.slice().reverse();

      const last = reversed.find(item => Ember.get(item, 'checked'));

      return last || options.get('lastObject');
    }),

    lowerOptions: Ember.computed.alias('options'),

    upperOptions: Ember.computed('options', 'lower', function () {
      const options = this.get('options');

      return options.slice(options.indexOf(this.get('lower')));
    }),

    recalculate() {
      const options = Ember.A(this.get('options'));

      if (this.get('all')) {
        this.set('checked', false);

        this.set('lower', options.get('firstObject'));
        this.set('upper', options.get('lastObject'));

        options.forEach(option => Ember.set(option, 'checked', true));
      } else if (!this.get('checked') && !this.get('singleMode')) {
        options.forEach(option => Ember.set(option, 'checked', false));
      }
    },

    isFullRange: Ember.computed('lower', 'upper', function () {
      return this.get('lower') === this.get('options.firstObject') && this.get('upper') === this.get('options.lastObject');
    }),

    updateOptions() {
      const options = this.get('options');
      const lower = this.get('lower');
      const upper = this.get('upper');

      options.forEach(option => Ember.set(option, 'checked', false));

      if (this.get('checked') || this.get('singleMode')) {
        for (let i = options.indexOf(lower); i <= options.indexOf(upper); i += 1) {
          Ember.set(options.objectAt(i), 'checked', true);
        }
      }
    },

    actions: {
      toggleRange() {
        const options = this.get('options');
        const checked = this.get('checked');

        if (checked) {
          this.set('lower', options.get('firstObject'));
          this.set('upper', options.get('lastObject'));

          this.set('all', false);

          this.updateOptions();
        } else {
          options.forEach(option => Ember.set(option, 'checked', false));
        }

        Ember.run.next(() => this.get('events').trigger('change'));
      },

      selectionChanged() {
        const options = this.get('options');

        const lower = this.get('lower');

        // this could technically be const...
        // but logically it should change based on below code from an atomic point of view
        let upper = this.get('upper');

        if (options.indexOf(lower) > options.indexOf(upper)) {
          this.set('upper', lower);

          upper = lower;
        }

        if (!this.get('isFullRange') && this.get('singleMode')) {
          this.set('all', false);
        }

        this.updateOptions();

        Ember.run.next(() => this.get('events').trigger('change'));
      }
    }
  }).reopenClass({
    positionalParams: ['range']
  });
});