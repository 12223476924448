define('oath-ui-components/components/oa-position/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const WHITE_SPACE_CHUNKER = /\s+/;

  const VERTICAL = /^(top|bottom)/;
  const HORIZONTAL = /^(left|right)/;

  const { flip } = Ember.$.ui.position;

  Ember.$.ui.position.flip = {
    left(position, { elem }) {
      const input = position.left;
      const output = flip.left(...arguments) || // eslint-disable-line prefer-rest-params
      position.left;

      if (input < output) {
        elem.attr('data-flip-x', 'right');
      } else if (input > output) {
        elem.attr('data-flip-x', 'left');
      } else {
        elem.removeAttr('data-flip-x');
      }

      return output;
    },

    top(position, { elem }) {
      const input = position.top;
      const output = flip.top(...arguments) || position.top; // eslint-disable-line prefer-rest-params

      if (input < output) {
        elem.attr('data-flip-y', 'bottom');
      } else if (input > output) {
        elem.attr('data-flip-y', 'top');
      } else {
        elem.removeAttr('data-flip-y');
      }

      return output;
    }
  };

  /**
   * `oa-position` is a simple wrapper around
   * [jQuery UI position](http://api.jqueryui.com/position/)
   *
   * @public
   * @module ui
   * @class PositionComponent
   * @namespace UI
   */
  exports.default = Ember.Component.extend({
    classNames: 'oa-position',

    tether: Ember.inject.service(),

    /**
     * options hash for [jQuery UI position](http://api.jqueryui.com/position/)
     *
     * @attribute options
     * @default null
     * @type object
     */
    options: null,

    optionsNormalized: Ember.computed('options', function () {
      const options = Object.assign({}, this.get('options'));

      options.of = options.of || this.$().parent();
      options.my = this.fix(options.my || 'center center');
      options.at = this.fix(options.at || 'center center');

      return options;
    }).readOnly(),

    position: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForQueue)('afterRender');

      const options = this.get('optionsNormalized');
      const my = options.my.split(WHITE_SPACE_CHUNKER).map(chunk => chunk.match(/(top|left|center|bottom|right)/)[0]);
      const at = options.at.split(WHITE_SPACE_CHUNKER).map(chunk => chunk.match(/(top|left|center|bottom|right)/)[0]);

      this.$().position({
        my: options.my,
        at: options.at,
        of: options.of,
        collision: options.collision,
        within: options.within,
        using(properties, { element: { top, left } }) {
          if (options.using) {
            options.using(...arguments); // eslint-disable-line prefer-rest-params
          } else {
            Ember.$(this).offset({ top, left });
          }
        }
      }).attr('data-my-x', my[0]).attr('data-my-y', my[1]).attr('data-at-x', at[0]).attr('data-at-y', at[1]);
    }).restartable(),

    tetherToPosition: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForEvent)(this.get('tether'), 'tick');

      this.rerender();

      this.get('tetherToPosition').perform();
    }).restartable(),

    fix(position) {
      const chunks = position.trim().split(WHITE_SPACE_CHUNKER);

      let [left, right] = chunks;

      if (chunks.length === 1) {
        if (VERTICAL.test(chunks[0])) {
          chunks.unshift('center');
        }
        if (HORIZONTAL.test(chunks[0])) {
          chunks.push('center');
        }

        [left, right] = chunks;
      }

      if (VERTICAL.test(left) || HORIZONTAL.test(right)) {
        return chunks.reverse().join(' ');
      }

      return chunks.join(' ');
    },

    willInsertElement() {
      this._super(...arguments); // eslint-disable-line prefer-rest-params

      this.get('tetherToPosition').perform();
    },

    willRender() {
      this._super(...arguments); // eslint-disable-line prefer-rest-params

      this.get('position').perform();
    },

    willDestroyElement() {
      this._super(...arguments); // eslint-disable-line prefer-rest-params

      this.get('position').cancelAll();
      this.get('tetherToPosition').cancelAll();

      this.$().position('destroy');
    }
  });
});