define('oath-ui-components/services/tether', ['exports', 'oath-ui-components/computed/raf', 'ember-concurrency'], function (exports, _raf, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    init(...args) {
      this._super(...args);

      this.get('tickTask').perform();

      window.addEventListener('scroll', Ember.run.bind(this, 'trigger', 'scheduleTick'), true);
      window.addEventListener('resize', Ember.run.bind(this, 'trigger', 'scheduleTick'), true);
    },

    tickTask: (0, _raf.default)({
      start() {
        return (0, _emberConcurrency.waitForEvent)(this, 'scheduleTick');
      },

      stop({ target, type }) {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        const size = `${width}x${height}`;

        if (type === 'scroll') {
          return `${target.scrollLeft}x${target.scrollTop}x${size}`;
        }

        return size;
      },

      update() {
        this.trigger('tick');
      }
    })
  });
});