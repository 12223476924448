define('oath-ui-components/components/oa-grid/td/component', ['exports', 'oath-ui-components/components/oa-grid/td/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-td',
    layout: _template.default,

    attributeBindings: ['column:data-column-id'],

    /**
     * @attribute {} column
     */
    column: null
  });
});