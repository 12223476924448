define('oath-ui-components/components/oa-group-creation/component', ['exports', 'oath-ui-components/components/oa-group-creation/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    searchPlaceholder: 'Search for an item',
    searchTerm: '',
    noIncludedData: 'No included items',
    noExcludedData: 'No excluded items',
    noSearchData: 'No results',
    isSearchFocus: false,
    showHeaders: false,
    tableClasses: Ember.computed('showHeaders', function () {
      return ['group-builder'].concat(this.get('showHeaders') ? [] : 'no-header').join(' ');
    }),
    searchTableClasses: Ember.computed('showHeaders', function () {
      return ['group-builder drop-down oa-grid--full-height'].concat(this.get('showHeaders') ? [] : 'no-header').join(' ');
    }),
    includedClasses: Ember.computed('includedData.@each', function () {
      return Ember.isEmpty(this.get('includedData')) ? 'no-header' : '';
    }),
    excludedClasses: Ember.computed('excludedData.@each', function () {
      return Ember.isEmpty(this.get('excludedData')) ? 'no-header' : '';
    }),
    searchClasses: Ember.computed('searchResultsData.@each', function () {
      return Ember.isEmpty(this.get('searchResultsData')) ? 'no-header' : '';
    }),

    actions: {
      focusIn() {
        this.set('isSearchFocus', true);
      },

      focusOut() {
        this.set('isSearchFocus', false);
      },

      clearSearchTerm() {
        this.set('searchTerm', '');
        this.set('isSearchFocus', false);
      }
    }
  });
});