define('oath-ui-components/components/oa-input-base/component', ['exports', 'oath-ui-components/utils/ddau'], function (exports, _ddau) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'label',

    /**
     * @public
     * @attribute value
     */
    value: null,

    /**
     * @public
     * @attribute isHover
     */
    isHover: false,

    /**
     * @protected
     * @property isFocused
     */
    isFocused: false,

    /**
     * @protected
     * @property isBlurred
     */
    isBlurred: true,

    /**
     * @event on-focus-in
     */
    focusIn(...args) {
      this._super(...args);

      this.set('isFocused', true);
      this.set('isBlurred', false);

      this.sendEventAction('on-focus-in');
    },

    /**
     * @event on-focus-out
     */
    focusOut(...args) {
      this._super(...args);

      this.set('isFocused', false);
      this.set('isBlurred', true);

      this.sendEventAction('on-focus-out');
    },
    /**
     * @event on-hover-in
     */
    mouseEnter(...args) {
      this._super(...args);
      this.set('isHover', true);

      this.sendEventAction('on-hover-in');
    },
    /**
     * @event on-over-out
     */
    mouseLeave(...args) {
      this._super(...args);
      this.set('isHover', false);

      this.sendEventAction('on-hover-out');
    },

    /**
     * @event on-change
     */
    change(...args) {
      this._super(...args);

      this.sendEventAction('on-change');
    },

    sendEventAction(actionName, updatedValue) {
      (0, _ddau.sendEventAction)(this, actionName, 'value', updatedValue);
    }
  }).reopenClass({
    positionalParams: ['value']
  });
});