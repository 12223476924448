define('oath-ui-components/components/oa-left-nav/sidebar/group/component', ['exports', 'oath-ui-components/components/oa-left-nav/sidebar/group/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: 'oa-left-nav__sidebar__group',
    classNameBindings: ['isActive:oa-left-nav__sidebar__group--active', 'isExpanded:oa-left-nav__sidebar__group--expanded'],
    title: null,
    icon: null,
    controller: {},
    didReceiveAttrs(...args) {
      this._super(...args);
      this.set('controller', {
        show: true
      });
      this.get('controllers').push(this.get('controller'));
    },
    isExpanded: Ember.computed('controller.show', function () {
      return this.get('controller').show;
    }),
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main'); // TODO use router service instead
    }).readOnly(),

    isActive: Ember.computed('router.currentRouteName', function () {
      return new RegExp(this.get('current-when')).test(this.get('router.currentRouteName'));
    }).readOnly(),

    actions: {
      toggleExpanded() {
        this.set('controller.show', !this.get('controller.show'));
      }
    }
  }).reopenClass({
    positionalParams: ['icon', 'title']
  });
});