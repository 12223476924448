define('oath-ui-components/components/oa-left-nav/sidebar/scroll-to/component', ['exports', 'oath-ui-components/components/oa-left-nav/sidebar/scroll-to/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isActive:active'],
    layout: _template.default,
    isActive: Ember.computed('model.anchor', function () {
      return this.get('model.anchor').includes(this.get('link'));
    }).readOnly(),
    click() {
      this.set('model.anchor', this.get('link'));
    }
  }).reopenClass({
    positionalParams: ['link']
  });
});