define('oath-ui-components/components/oa-grid/thead/component', ['exports', 'oath-ui-components/utils/dom', 'oath-ui-components/mixins/styleable', 'oath-ui-components/components/oa-grid/thead/template'], function (exports, _dom, _styleable, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_styleable.default, {
    classNames: 'oa-thead',
    layout: _template.default,

    leafNodes: Ember.computed(function () {
      return this.$('[data-column-children="0"]');
    }).readOnly(),

    ns: Ember.computed(function () {
      return this.$().closest('.oa-table--inner').attr('id');
    }).readOnly(),

    didInsertElement(...args) {
      this._super(...args);

      this.$('.oa-sortable').on('sortupdate', (evt, { item }) => {
        const ordered = item.closest('.oa-thead').find('.oa-th[data-column-id]');

        ordered.attr('data-column-order', index => index);

        Ember.run(this, 'rerender');
      });
      this.$().on('resize', () => this.rerender());
    },

    didRender(...args) {
      this._super(...args);

      const ns = this.get('ns');
      const nodes = this.get('leafNodes').toArray();
      const widths = nodes.map(el => Ember.$(el).attr('data-column-width'));
      const paddings = nodes.map(Ember.$).map(el => {
        let padding = el.attr('data-padding');

        if (!padding) {
          const { 'padding-left': left, 'padding-right': right } = el.css(['padding-left', 'padding-right']);

          padding = `${parseFloat(left)},${parseFloat(right)}`;

          el.attr('data-padding', padding);
        }

        return padding.split(',').map(Number);
      });

      Ember.run.schedule('afterRender', this, function () {
        // TODO handle breakpoints
        (0, _dom.layout)(this.$().width(), widths).forEach((width, index) => {
          const [left, right] = paddings[index];
          const node = Ember.$(nodes[index]);
          const order = node.attr('data-column-order');
          const id = node.attr('data-column-id');

          if (left + right > width) {
            const ratio = width / (left + right);

            this.style(`#${ns} [data-column-id="${id}"]`, {
              order: order || null,
              'padding-left': `${left * ratio}px !important`,
              'padding-right': `${right * ratio}px !important`,
              width: `${width}px !important`,
              'min-width': `${width}px !important`
            });
          } else {
            this.style(`#${ns} [data-column-id="${id}"]`, {
              order: order || null,
              'padding-left': null,
              'padding-right': null,
              width: `${width}px !important`,
              'min-width': `${width}px !important`
            });
          }
        });
      });
    },

    willDestroyElement(...args) {
      this._super(...args);

      this.$().off();
    },

    actions: {
      rerender() {
        this.rerender();
      }
    }
  });
});