define('oath-ui-components/components/oa-datatables/options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    defaultOptions: {
      columns: undefined,
      data: undefined,
      lengthChange: false,
      info: false,
      load: undefined,
      searching: false,
      pagingType: 'full_numbers',
      defRender: false,
      scrollY: undefined,
      scrollCollapse: undefined,
      scroller: false,
      stripeClasses: []
    },
    inlineEdit: false,
    inlineProgressBar: false,
    tableOptions: {},
    options: Ember.computed('tableOptions', {
      get() {
        const defaultOptions = Ember.copy(this.get('defaultOptions'), true);

        return Ember.assign(defaultOptions, this.get('tableOptions'), {
          columns: this.get('processedColumns'),
          ajax: this.wrapAjaxCall(),
          serverSide: !!this.get('load'),
          treeView: this.wrapTreeViewOptions()
        });
      }
    }).readOnly(),
    onRegisterApi: () => undefined, // TODO: this is a callback for future use.

    processedColumns: Ember.computed('columns', function () {
      return this.get('columns').map(column => {
        if (Ember.typeOf(column) === 'instance') {
          return column.get('processed');
        }
        return column;
      });
    }),

    /*
     * Computed property from `data`.
     * DataTables expects a certain data format, incompatible with Ember's
     * `AdapterPopulatedRecordArray`.
     */
    processedData(data) {
      if (!data) {
        return data;
      }

      return data.map(row => {
        const record = row.getRecord ? row.getRecord() : row;
        return Object.assign({}, record.toJSON({ includeId: true }), { _record: record });
      });
    },

    wrapAjaxCall() {
      if (!this.get('load')) {
        return undefined;
      }

      return (data, callback, settings) => this.get('load')(data, settings).then(response => callback({
        recordsTotal: response.meta.total,
        recordsFiltered: response.meta.total,
        data: this.processedData(response.content)
      }));
    },

    wrapTreeViewOptions() {
      const options = this.get('tableOptions.treeView');
      if (!options) {
        return undefined;
      }

      return Object.assign({}, options, {
        // See `processedData`, DataTables and Ember talk about different records
        // Purposely using an underscore dangle to avoid naming conflicts.
        // eslint-disable-next-line no-underscore-dangle
        hasChildren: row => options.hasChildren(row._record),
        getChildren: (row, callback) => {
          // eslint-disable-next-line no-underscore-dangle
          options.getChildren(row._record).then(response => {
            callback(this.processedData(response.content));
          });
        }
      });
    }
  });
});