define('@ouroath/oath-ui-authentication/adapters/one-central', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    host: 'https://qa.bump.aol.com',
    authorizer: 'authorizer:oneCentral'
  });
});