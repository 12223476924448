define('oath-ui-components/components/oa-grid/tfoot/component', ['exports', 'oath-ui-components/components/oa-grid/tfoot/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['oa-tfoot', 'oa-table__section'],
    layout: _template.default
  });
});