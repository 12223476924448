define('oath-ui-components/components/oa-sortable/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-sortable',

    // attrs {

    /**
     * options hash for [jQuery UI sortable](http://api.jqueryui.com/sortable/)
     *
     * @attribute options
     * @default null
     * @type object
     */
    options: null,

    // attrs }

    didRender(...args) {
      this._super(...args);

      this.$().sortable(Object.assign({}, this.get('options')));
    },

    willDestroyElement(...args) {
      this._super(...args);

      this.$().sortable('destroy');
    }
  });
});