define('oath-ui-components/utils/dom', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.swapNodes = swapNodes;
  exports.getBox = getBox;
  exports.layout = layout;


  const PLACEHOLDER = Ember.$(document.createTextNode(''));

  function swapNodes(...args) {
    const [left, right] = args.map(Ember.$);

    PLACEHOLDER.insertBefore(left);

    left.insertBefore(right);
    right.insertAfter(PLACEHOLDER);

    PLACEHOLDER.remove();
  }

  function getBox(el) {
    if (!el) {
      return {
        width: 0,
        height: 0,

        scrollWidth: 0,
        scrollHeight: 0,

        margin: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
        border: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }
      };
    }

    const element = el instanceof Ember.$ ? el.get(0) : el;

    const css = getComputedStyle(element);

    return {
      width: parseFloat(css.getPropertyValue('width')),
      height: parseFloat(css.getPropertyValue('height')),

      scrollWidth: element.scrollWidth,
      scrollHeight: element.scrollHeight,

      margin: {
        top: parseFloat(css.getPropertyValue('margin-top')),
        right: parseFloat(css.getPropertyValue('margin-right')),
        bottom: parseFloat(css.getPropertyValue('margin-bottom')),
        left: parseFloat(css.getPropertyValue('margin-left'))
      },
      padding: {
        top: parseFloat(css.getPropertyValue('padding-top')),
        right: parseFloat(css.getPropertyValue('padding-right')),
        bottom: parseFloat(css.getPropertyValue('padding-bottom')),
        left: parseFloat(css.getPropertyValue('padding-left'))
      },
      border: {
        top: parseFloat(css.getPropertyValue('border-top-width')),
        right: parseFloat(css.getPropertyValue('border-right-width')),
        bottom: parseFloat(css.getPropertyValue('border-bottom-width')),
        left: parseFloat(css.getPropertyValue('border-left-width'))
      }
    };
  }

  function layout(width, portions) {
    let fr = 0;
    let available = width;

    return portions.map(portion => {
      if (typeof portion === 'number') {
        return {
          value: portion,
          unit: 'px'
        };
      } else if (portion === null) {
        return {
          value: 0,
          unit: 'null'
        };
      }

      const expr = portion.match(/([\d.]+)(px|%|fr)/);

      (true && !(expr) && Ember.assert(`Layout expression ${portion} not recognized`, expr));


      const [, value, unit] = expr;

      return {
        value: Number(value),
        unit
      };
    }).map(portion => {
      let { value } = portion;
      const { unit } = portion;

      if (unit === 'px') {
        available -= value;

        return value;
      }
      if (unit === '%') {
        value = width * value / 100;

        available -= value;

        return value;
      }
      if (unit === 'fr') {
        fr += value;

        return portion;
      }
      if (unit === 'null') {
        return portion;
      }

      (true && !(false) && Ember.assert(`Unrecognized unit ${unit}`));

      return null;
    }).map(portion => {
      if (typeof portion === 'number') {
        return portion;
      }
      if (portion.unit === 'fr') {
        return available * portion.value / fr;
      }
      if (portion.unit === 'null') {
        return null;
      }
      (true && !(false) && Ember.assert(`Unrecognized unit ${portion.unit}`));

      return null;
    });
  }
});