define('oath-ui-components/mixins/oa-datatables/custom-component-renderer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    childComponents: Ember.computed(() => Ember.A()),
    runtimeComponentRenderer: Ember.inject.service(),

    destroyChildComponents() {
      while (this.get('childComponents').length > 0) {
        this.get('childComponents').pop().destroy();
      }
    },

    renderCustomComponent(elementId, value, record, api, column) {
      const boundComponent = column.get('component').bind(value, record, api);
      this.get('runtimeComponentRenderer').renderChildComponent(column.get('component.name'), boundComponent).then(result => {
        const { element, root } = result;
        this.get('childComponents').push(root);
        const parent = document.getElementById(elementId);
        root.result.bounds.parent = parent;
        Ember.$(parent).html(element);
      });
    }
  });
});