define('oath-ui-components/components/oa-range-selector/node/component', ['exports', 'oath-ui-components/components/oa-range-selector/node/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNameBindings: ['selectedVisual', 'isLongValue'],
    classNames: ['oa-range-selector__node'],

    value: null, // handed down from row component
    selected: false, // value selected indicator
    selectedVisual: false, // visual selected indicator
    isDragging: null, // handed down from row component

    // used to add css padding on our wider values
    isLongValue: Ember.computed('value', function () {
      if (this.get('value').toString().length > 2) {
        return true;
      }
      return false;
    }),

    mouseDown() {
      this.sendAction('setStart', this.get('value'));
    },

    mouseUp() {
      this.sendAction('setEnd', this.get('value'), false);
    },

    mouseEnter() {
      if (this.get('isDragging')) {
        this.sendAction('setEnd', this.get('value'), true);
      }
    }

  });
});