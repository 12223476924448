define('oath-ui-components/components/oa-range-selector/component', ['exports', 'oath-ui-components/components/oa-range-selector/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    allLabel: 'All',
    noRangesDetectedLabel: 'No Ranges Detected',
    dragToSelectRangeLabel: 'Drag to select range',
    clickAndDragToSelectRangeLabel: 'Click and drag to select range',
    selection: [], // internal storage for our final compiled selection
    classNames: ['oa-range-selector'],
    checked: Ember.computed('preSelection', function () {
      if (this.get('preSelection').length) {
        return false;
      }
      return true;
    }), // checked/intermdiate/disabled are our 'All' checkbox states
    disabled: false,

    /*
      selectAllHidden is our state that tracks our initial
      'everything is selected but visually only 'All' is checked'
    */
    selectAllHidden: Ember.computed('preSelection', function () {
      if (this.get('preSelection').length) {
        return false;
      }
      return true;
    }),
    rowMaxWidth: null, // pass in '400px' or '75%'(etc) to set max width of row ranges
    optionButtonWidth: null, // pass in '400px' or '75%'(etc) to set width of options buttons

    /*
    dev input for ranges, accepts simple [1,2,3] or multiple object/ember objects
      [{
        dogs: ['fido', 'spot', 'patches']
      }, {
        cats: ['tigger', 'paws', 'galanthor'
      }, {
        ages: [1, 2, 3]
      }]
    */
    ranges: null,

    preSelection: [], // used to keep track of selections we want pre-loaded

    /*
      rangedDetailed expands our range data to be usable in our component.
      it gets "eached" in template and each range gets turned into a
      row and accompanying option button
    */
    rangesDetailed: Ember.computed('ranges', function () {
      const { ranges, preSelection } = this.getProperties('ranges', 'preSelection');

      if (ranges.length) {
        if (typeof ranges[0] === 'object') {
          // the case for multiple ranges
          return ranges.map(rangeRow => {
            const rangeRowName = Object.keys(rangeRow)[0];
            const preSelectionRow = preSelection.filter(preSelectionRowObj => rangeRowName === Object.keys(preSelectionRowObj)[0]);
            const formattedRow = Ember.Object.create({
              name: rangeRowName,
              values: rangeRow[rangeRowName],
              // selectAllBound - method on row to be called on children (a bound selectAll function)
              selectAllBound: null,
              // allSelected tracks if our entire range is selected-
              // needed for 'all' checked status
              allSelected: false,

              // we pass in the start and end of our preselections
              preSelectionStart: preSelectionRow.length ? preSelectionRow[0][rangeRowName][0] : 0,
              preSelectionEnd: preSelectionRow.length ? preSelectionRow[0][rangeRowName][preSelectionRow[0][rangeRowName].length - 1] : 0
            });

            // observers needed to uptdate our 'all' status on depending on aggregate child changes
            formattedRow.addObserver('allSelected', this, function () {
              this.triggerHasSelection();
            });

            return formattedRow;
          });
        }

        // the case for a simple range
        const formattedRow = [Ember.Object.create({
          name: '',
          values: ranges,
          selectAllBound: null,
          allSelected: true,
          preSelectionStart: preSelection.length ? preSelection[0] : 0,
          preSelectionEnd: preSelection.length ? preSelection[preSelection.length - 1] : 0
        })];
        formattedRow[0].addObserver('allSelected', this, function () {
          this.triggerHasSelection();
        });

        return formattedRow;
      }
      return null;
    }),

    init(...args) {
      this._super(...args);
      // this relays our ranges straight to our output for our default state of all selected
      if (!this.get('preSelection').length) {
        this.sendAction('setSelection', this.get('ranges'));
      }
    },

    // this is triggered when a child component has its whole range selected or part selected
    // we use this to decide the status of our 'all' checkbox
    triggerHasSelection() {
      let hasAll = true;
      this.get('rangesDetailed').forEach(obj => {
        if (!obj.get('allSelected')) {
          hasAll = false;
        }
      });

      if (hasAll) {
        this.setProperties({
          checked: true,
          selectAllHidden: true
        });
        this.get('rangesDetailed').forEach(obj => {
          Ember.run.next(() => {
            obj.get('selectAllBound').selectAllHiddenToggle();
          });
        });
      } else {
        this.setProperties({
          checked: false
        });
      }
    },

    actions: {
      // relays our compiled selection to our controller, calling the action 'setSelection'
      setSelection(name, range) {
        // our multiple ranges pass names and trigger this case
        if (name) {
          const selection = this.get('selection');
          const existingRange = selection.find(obj => Object.keys(obj)[0] === name);

          if (existingRange) {
            existingRange.set(name, range);
          } else {
            const newRange = Ember.Object.create({});
            newRange.set(name, range);
            selection.push(newRange);
          }
          this.set('selection', selection);
          this.sendAction('setSelection', this.get('selection'));

          // this case is triggered for our simple (single) range
        } else {
          this.set('selection', range);
          this.sendAction('setSelection', this.get('selection'));
        }
      },

      // toggleAll decides what clicking our 'all' check does
      toggleAll() {
        if (this.get('checked') || this.get('indeterminate')) {
          // note- selectAllHidden trickles to each child component- this turns off
          // default all select.
          this.setProperties({
            selectAllHidden: false,
            checked: false
          });

          // we run this in the next loop because the manually called _selectAllHidden
          // depends on the selectAllHidden property handed down to each child row
          Ember.run.next(() => {
            this.get('rangesDetailed').forEach(obj => {
              // this calls an action our our child component
              obj.get('selectAllBound').selectAllHiddenToggle();
            });
          });
          // in this case we'll select everything, but only the 'all' check
          // will visibly be checked. (per ux requirement)
        } else {
          this.setProperties({
            selectAllHidden: true,
            checked: true
          });
          this.get('rangesDetailed').forEach(obj => {
            Ember.run.next(() => {
              obj.get('selectAllBound').selectAllHiddenToggle();
            });
          });
        }
      }
    }
  });
});