define('oath-ui-components/services/oa-icon-cache', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    oaAsset: Ember.inject.service(),
    ajax: Ember.inject.service(),

    rootElement: Ember.computed(function () {
      return Ember.$(Ember.getOwner(this).rootElement);
    }).readOnly(),
    svg: Ember.computed(() => Ember.$('<svg id="oa-icon-collection" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"></svg>')).readOnly(),

    init(...args) {
      this._super(...args);

      this.get('rootElement').append(this.get('svg'));
    },

    destroy(...args) {
      this._super(...args);

      this.get('svg').remove();
    },

    load: (0, _emberConcurrency.task)(function* () {
      const ajax = this.get('ajax');
      const svg = this.get('svg');

      const result = yield this.get('oaAsset.load').linked().perform('icons', function* () {
        const doc = yield ajax.request('/assets/images/oa-icons.svg', { dataType: 'xml' });

        const icons = doc.lastChild.lastChild.childNodes;
        const map = new Map();
        icons.forEach(icon => {
          // FIXME do not use private API
          const guid = Ember.generateGuid(this, 'icon-');
          const key = icon.id;
          const value = `${guid}-${key}`;

          Object.assign(icon, { id: value });

          map.set(key, value);
        });

        svg.append(icons);

        return map;
      });

      return result;
    }).drop().on('init')
  });
});