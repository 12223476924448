define('oath-ui-components/computed/tree', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = tree;


  const TreeModel = Ember.ArrayProxy.extend({
    root: null,

    rootArray: Ember.computed('root.[]', function () {
      return this.get('root').map(node => this.createTreeNode(0, node));
    }).readOnly(),

    content: Ember.computed('rootArray', function () {
      function flatten(value) {
        const array = value || [];

        return [].concat(...array.map(node => {
          const meta = node.get('$meta');

          if (meta.get('aggregation')) {
            return [node].concat(flatten(meta.get('children')));
          }

          return node;
        }));
      }
      return flatten(this.get('rootArray'));
    }).readOnly(),

    init(...args) {
      this._super(...args);

      this.down = this.down.bind(this);
      this.up = this.up.bind(this);
      this.toggle = this.toggle.bind(this);
    },

    createTreeNode(level, content) {
      return Ember.ObjectProxy.create({
        content,

        $meta: Ember.Object.create({
          level,
          aggregation: false,
          selection: false,
          children: null
        })
      });
    },

    async down(node, children) {
      const meta = node.get('$meta');

      meta.set('aggregation', true);

      if (!meta.get('children')) {
        const value = typeof children === 'function' ? children(node) : children;

        meta.set('children', _emberData.default.PromiseArray.create({
          promise: Ember.RSVP.resolve(value).then(items => items.map(item => this.createTreeNode(meta.get('level') + 1, item)))
        }));
      }

      this.notifyPropertyChange('content');

      await meta.get('children');

      this.notifyPropertyChange('content');
    },

    up(node) {
      const meta = node.get('$meta');

      meta.set('aggregation', false);

      this.notifyPropertyChange('content');
    },

    toggle(node, children) {
      const meta = node.get('$meta');

      if (meta.get('aggregation')) {
        return this.up(node);
      }

      return this.down(node, children);
    }
  });

  function tree(...args) {
    let key;
    let extension;
    if (args.length === 1 && typeof args[0] === 'object') {
      [key, extension] = [undefined, args[0]];
    } else {
      [key, extension] = args;
    }

    const type = typeof key;
    const Factory = extension ? TreeModel.extend(extension) : TreeModel;

    if (type === 'string') {
      return Ember.computed(key, function () {
        return Factory.create({
          root: this.get(key)
        });
      }).readOnly();
    }

    if (type === 'undefined') {
      return Ember.computed({
        get(k) {
          throw new Error(`${k} must be set`);
        },

        set(k, value) {
          return Factory.create({
            root: _emberData.default.PromiseArray.create({
              promise: Ember.RSVP.resolve(value)
            })
          });
        }
      });
    }

    throw new Error(`Unrecognized type ${type}`);
  }
});