define('oath-ui-components/components/oa-grid/tbody/component', ['exports', 'ember-concurrency', 'oath-ui-components/components/oa-grid/tbody/template'], function (exports, _emberConcurrency, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['oa-tbody', 'oa-table__section'],
    layout: _template.default,

    hover: (0, _emberConcurrency.task)(function* () {
      const ns = this.elementId;
      const { element } = this;
      const doc = Ember.$(document);
      let hover = null;

      while (true) {
        const { target } = yield (0, _emberConcurrency.waitForEvent)(doc, `mouseover.${ns}`);

        yield (0, _emberConcurrency.waitForQueue)('afterRender');

        if (hover) {
          hover.removeClass('oa-tr--hover');
        }

        if (element === target || element.contains(target)) {
          const index = Ember.$(target).closest('.oa-tr').attr('data-row-index');

          hover = this.$(`[data-row-index=${index}]`);

          hover.addClass('oa-tr--hover');
        }
      }
    }),

    didInsertElement(...args) {
      this._super(...args);

      this.get('hover').perform();
    },

    willDestroyElement(...args) {
      this._super(...args);

      this.get('hover').cancelAll();
    }
  });
});