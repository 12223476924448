define('oath-ui-components/components/oa-grid/table--inner/component', ['exports', 'ember-concurrency', 'oath-ui-components/computed/raf', 'oath-ui-components/components/oa-grid/table--inner/template'], function (exports, _emberConcurrency, _raf, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-table--inner',
    layout: _template.default,

    synchronizeHorizontalScroll: (0, _raf.default)({
      *start({ contents }) {
        const thead = contents.filter('.oa-thead__content');
        const tbody = contents.filter('.oa-tbody__content');
        const tfoot = contents.filter('.oa-tfoot__content');

        yield (0, _emberConcurrency.waitForEvent)(tbody, 'scroll');

        return { thead, tbody, tfoot };
      },

      stop({ tbody }) {
        return tbody.scrollLeft();
      },

      update({ thead, tfoot }, scrollLeft) {
        thead.scrollLeft(scrollLeft);
        tfoot.scrollLeft(scrollLeft);
      }
    }),

    didRender(...args) {
      this._super(...args);

      const vScroller = this.$('[data-table-block="variable"]');
      const hScroller = vScroller.find('.oa-tbody__content');
      const contents = this.$('.oa-thead__content, .oa-tbody__content, .oa-tfoot__content');
      const body = contents.filter('.oa-tbody__content');
      const rows = body.find('[data-row-index]');
      const first = rows.first().attr('data-row-index');
      const last = rows.last().attr('data-row-index');

      this.get('synchronizeHorizontalScroll').perform({
        scroller: {
          horizontal: hScroller,
          vertical: vScroller
        },
        contents
      });

      if (rows.length) {
        /**
         * @event on-scroll
         */
        Ember.run.schedule('actions', this, 'sendAction', 'on-scroll', [first, last].map(Number));
      }
    },

    willDestroyElement(...args) {
      this._super(...args);

      this.get('synchronizeHorizontalScroll').cancelAll();
    }
  });
});