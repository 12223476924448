define('oath-ui-components/components/oa-backdrop/page-object', ['exports', 'oath-ui-components/components/page-object'], function (exports, _pageObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  class BackdropComponent extends _pageObject.default {
    constructor(options) {
      super(Object.assign({ scope: '.oa-backdrop' }, options));
    }
  }
  exports.default = BackdropComponent;
});