define('oath-ui-components/components/oa-rightrail/component', ['exports', 'oath-ui-components/components/oa-rightrail/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: 'oa-rightrail',
    tabs: null,
    hasMultipleTabs: Ember.computed.gt('tabs.length', 1)
  });
});