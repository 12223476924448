define('oath-ui-components/components/oa-assign-remove/component', ['exports', 'oath-ui-components/components/oa-assign-remove/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-assign-remove'],
    assignTooltipMessage: 'Assign',
    unassignTooltipMessage: 'Unassign',
    assignedTooltipMessage: 'Assigned!',
    unassignedTooltipMessage: 'Unassigned!',
    tooltipPosition: 'right',
    tooltipAutoHorizontal: false,
    icon_class: 'add',
    is_clickable: true,
    value: false,
    is_hovered: false,

    tooltip_message: Ember.computed('value', 'is_clickable', function () {
      if (this.get('is_clickable')) {
        return this.get('value') ? this.get('unassignTooltipMessage') : this.get('assignTooltipMessage');
      }
      return this.get('value') ? this.get('assignedTooltipMessage') : this.get('unassignedTooltipMessage');
    }),

    div_class: Ember.computed('value', 'is_clickable', function () {
      if (this.get('is_clickable')) {
        return this.get('value') ? 'unassign-btn' : 'assign-btn';
      }
      return this.get('value') ? 'btn-on' : 'btn-off';
    }),

    icon: Ember.computed('value', 'is_clickable', 'is_hovered', function () {
      if (this.get('is_clickable')) {
        if (this.get('value')) {
          return this.get('is_hovered') ? 'x-circle-fill' : 'checkmark-circle-fill';
        }
        return 'add';
      }
      return this.get('value') ? 'checkmark-circle-fill' : 'x-circle-fill';
    }),

    actions: {
      click() {
        if (this.get('is_clickable')) {
          this.set('value', !this.get('value'));
          this.set('is_clickable', false);
        }
      },
      mouseLeave() {
        this.set('is_hovered', false);
        this.set('is_clickable', true);
      },
      mouseEnter() {
        if (this.get('value')) {
          this.set('is_hovered', true);
        }
      }
    }

  });
});