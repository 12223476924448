define('oath-ui-components/components/oa-rightrail/tabs/component', ['exports', 'oath-ui-components/components/oa-rightrail/tabs/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['oa-rightrail__tabs'],
    layout: _template.default,
    actions: {
      tabChange(clickIndex) {
        this.get('tabs').forEach((tab, index) => {
          Ember.set(tab, 'selected', clickIndex === index);
        });
      }
    }
  });
});