define('oath-ui-components/components/oa-grid/inline-basic-edit/component', ['exports', 'oath-ui-components/components/oa-grid/inline-basic-edit/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    actions: {
      onCommit(value, item, column) {
        item.set(column.get('property'), value);
        return true;
      }
    }
  });
});