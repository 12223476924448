define('oath-ui-components/helpers/tree-indent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.treeIndent = treeIndent;
  function treeIndent(params) {
    return Ember.String.htmlSafe(`${params[0]}:${params[1] * params[2]}px`);
  }

  exports.default = Ember.Helper.helper(treeIndent);
});