define('oath-ui-components/components/oa-datatables/component', ['exports', 'lodash', 'oath-ui-components/mixins/oa-datatables/custom-component-renderer', 'oath-ui-components/components/oa-datatables/template'], function (exports, _lodash, _customComponentRenderer, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const OaDataTable = Ember.Component.extend(_customComponentRenderer.default, {
    oaIconCache: Ember.inject.service(),
    layout: _template.default,
    gridOptions: null,
    api: null,
    treeViewIconIds: null,
    sortingIconIds: {},

    assignGridColumns() {
      this.get('gridOptions').get('columns').forEach(column => this.assignColumnToGrid(column));
    },

    buildIcon(iconId, cssClass = '') {
      return `<svg class="${cssClass}"><use xlink:href="#${iconId}"></use></svg>`;
    },

    buildSortingIcon(sortBy, sortType) {
      this.$(`table > thead th:eq(${sortBy}):not(.sorting_disabled)`).append(this.buildIcon(this.get('sortingIconIds')[sortType], 'oa-sorting-icon'));
    },

    assignColumnToGrid(column) {
      column.assignToGrid(this);
    },

    renderCellComponent(value, record, column) {
      const elementId = (0, _lodash.uniqueId)('oa-dt-temp-cell-');

      this.renderCustomComponent(elementId, value, record, this.get('api'), column);

      return `<div id="${elementId}" class="oa-datatables--custom-cell__content"></div>`;
    },

    didInsertElement(...args) {
      this._super(...args);

      this.loadIconCache().then(() => this.assignGridColumns()).then(() => this.initDataTables()).then(() => this.registerSortingEvents());
    },

    initDataTables() {
      return new Ember.RSVP.Promise(resolve => {
        const options = this.get('gridOptions').get('options');
        Object.assign(options, {
          drawCallback: () => this.onDtDraw()
        });
        const api = this.$('table').DataTable(options);
        this.set('api', api);
        this.get('gridOptions').onRegisterApi(api);
        resolve(api);
      });
    },

    loadIconCache() {
      return this.get('oaIconCache.load.last').then(icons => {
        this.set('sortingIconIds', {
          asc: icons.get('chevron-down'),
          desc: icons.get('chevron-up')
        });
        this.set('treeViewIconIds', {
          expand: icons.get('chevron-down-thick')
        });
      });
    },

    normalizeTreeViewOptions: Ember.observer('treeViewIconIds', function () {
      const { tableOptions } = this.get('gridOptions');
      const icons = this.get('treeViewIconIds');

      if (Ember.isBlank(icons)) return;
      if (Ember.isBlank(tableOptions.treeView)) return;

      tableOptions.treeView = Object.assign({}, {
        expandIcon: `<button>${this.buildIcon(icons.expand)}</button>`,
        collapseIcon: null
      }, tableOptions.treeView);
    }),

    onDataChange: Ember.observer('gridOptions.data', function () {
      if (!this.get('api')) return;
      this.get('api').clear().rows.add(this.get('gridOptions').processedData(this.get('gridOptions').get('data'))).draw();
    }),

    onDtDraw() {
      this.destroyChildComponents();
    },

    registerSortingEvents() {
      const api = this.get('api');
      api.on('order.dt', () => {
        this.$('table > thead .oa-sorting-icon').remove();
        this.buildSortingIcon(...api.order()[0]);
      });
      this.buildSortingIcon(...api.order()[0]);
    },

    willDestroyElement(...args) {
      this._super(...args);
      this.destroyChildComponents();
    }
  });

  OaDataTable.reopenClass({
    positionalParams: ['gridOptions']
  });

  exports.default = OaDataTable;
});