define('ember-microstates/helpers/string', ['exports', 'ember-microstates'], function (exports, _emberMicrostates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberMicrostates.MicroState.extend({

    initialValueFor([string = '']) {
      if (string == null) {
        return '';
      } else {
        return String(string);
      }
    },

    prototypeFor(value) {
      let wrapped = new String(value);

      Object.defineProperties(wrapped, {
        toString: {
          value() {
            return value;
          }
        },
        valueOf: {
          value() {
            return value;
          }
        }
      });

      return wrapped;
    }
  });
});