define('oath-ui-components/components/oa-select/component', ['exports', 'ember-power-select/components/power-select'], function (exports, _powerSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _powerSelect.default.extend({
    searchEnabled: false,
    classNames: ['oa-select'],
    tagName: 'div'
  });
});