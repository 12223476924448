define('oath-ui-components/components/oa-export/component', ['exports', 'oath-ui-components/components/oa-export/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: ['oa-export'],

    fileName: '',

    fileExt: 'text/csv;charset=utf-8',

    items: [],

    anchor: document.createElement('a'),

    URL: window.URL || window.webkitURL,

    exportListLabel: 'Export list',

    saveAs(file, fileName) {
      const URL = this.get('URL');

      if (typeof URL.createObjectURL === 'function') {
        const objectURL = URL.createObjectURL(file);
        const anchor = this.get('anchor');
        anchor.href = objectURL;
        anchor.download = fileName;
        anchor.click();
        URL.revokeObjectURL(objectURL);
      } else {
        throw new Error('No File API detected');
      }
    },

    actions: {
      exportFile() {
        const blob = new Blob([this.get('items').toString().replace(/,\n/g, '\n')], { type: this.get('fileExt') });
        this.saveAs(blob, this.get('fileName'));
      }
    }
  });
});