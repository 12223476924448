define('oath-ui-components/components/oa-left-nav/component', ['exports', 'oath-ui-components/components/oa-left-nav/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-left-nav',
    layout: _template.default,
    model: Ember.computed(() => ({ anchor: '' })),
    default: null,
    init(...args) {
      this._super(...args);
      this.set('model.anchor', this.get('default'));
    }
  });
});