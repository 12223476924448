define('oath-ui-components/components/oa-left-nav/sidebar/link-to/component', ['exports', 'oath-ui-components/components/oa-left-nav/sidebar/link-to/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({
    layout: _template.default,
    classNames: 'oa-left-nav__sidebar__link',
    icon: null,
    title: null
  });
});