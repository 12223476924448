define('oath-ui-components/components/oa-datatables/inline-edit/component', ['exports', 'oath-ui-components/mixins/oa-datatables/custom-component-renderer', 'oath-ui-components/components/oa-datatables/inline-edit/template'], function (exports, _customComponentRenderer, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const InlineEditComponent = Ember.Component.extend(_customComponentRenderer.default, {
    layout: _template.default,

    anchorCell: null,
    column: null,
    dtApi: null,
    errorMessage: null,
    gridOptions: null,
    model: null,
    saving: false,
    value: null,
    isLoaderVisible: false,

    didRender(...args) {
      this._super(...args);

      if (!this.get('dtApi')) {
        return;
      }

      Ember.$(document).off('click.outside');

      Ember.$(document).on('click.outside', this.get('onOutsideClickHandler').bind(this));

      // TODO: this is needed to avoid multiple event being attached on each rerender,
      // but we need to think about better solution
      Ember.$(this.get('dtApi').table().node()).off('dblclick', 'td.oa-datatables--inline-edit-cell');

      Ember.$(this.get('dtApi').table().node()).on('dblclick', 'td.oa-datatables--inline-edit-cell', event => this.onCellDblClick(event));
    },

    willDestroyElement(...args) {
      this._super(...args);
      this.destroyChildComponents();

      Ember.$(document).off('click.outside');
    },

    keyDown(event) {
      if (event.keyCode === 13) {
        this.submit();
      }

      if (event.keyCode === 27) {
        this.reset();
      }
    },

    onOutsideClickHandler(event) {
      const $element = this.$();
      const $target = Ember.$(event.target);

      if (!$target.closest($element).length && this.get('model')) {
        this.reset();
      }
    },

    cellWidth: Ember.computed('anchorCell', function () {
      return Ember.String.htmlSafe((() => {
        if (!this.get('anchorCell') || this.get('customComponent')) return '';

        return `width: ${this.get('anchorCell').clientWidth}px;`;
      })());
    }),

    fieldLabel: Ember.computed('column', function () {
      if (!this.get('column')) return '';
      return this.get('column').get('title');
    }),

    focusAndSelectTextOnAppear: Ember.observer('visible', function () {
      if (this.get('visible')) {
        this.selectAll();
      }
    }),

    onCellDblClick(event) {
      const cell = event.target.tagName === 'TD' ? event.target : Ember.$(event.target).parents('td')[0];

      this.startEdit(cell);
    },

    position: Ember.computed('anchorCell', function () {
      return Ember.String.htmlSafe((() => {
        if (!this.get('anchorCell')) return '';

        const offset = Ember.$(this.get('anchorCell')).offset();
        return `top: ${offset.top}px; left: ${offset.left}px;`;
      })());
    }),

    reset() {
      if (this.get('model')) {
        this.get('model').rollbackAttributes();
      }
      this.set('column', null);
      this.set('errorMessage', null);
      this.set('model', null);
      this.set('saving', false);
      this.set('isLoaderVisible', false);
    },

    submit() {
      const { data, component } = this.get('column');
      const model = this.get('model');
      model.set(data, component ? this.getCurrentValue() : this.get('value'));
      this.set('saving', true);
      this.set('isLoaderVisible', true);
      this.set('errorMessage', null);
      return model.save().then(() => {
        this.get('dtApi').draw('page');
        this.set('isLoaderVisible', false);
        setTimeout(() => this.reset(), 1000);
      }).catch(error => {
        this.set('errorMessage', error.message);
      });
    },

    getCurrentValue() {
      return this.get('model').get(this.get('column').data);
    },

    selectAll() {
      // Timeout needed because of timing between clicks and popup appearance
      setTimeout(() => this.$('.form-control').focus().select(), 50);
    },

    customComponent: Ember.computed.alias('column.component'),

    onCustomComponentChange: Ember.observer('customComponent', function () {
      if (this.get('customComponent')) {
        Ember.run.scheduleOnce('afterRender', () => {
          const elementId = 'temp-cell';

          this.renderCustomComponent(elementId, this.get('value'), this.get('model'), this.get('dtApi'), this.get('column'));
        });
      }
    }),

    startEdit(cell) {
      this.reset();
      this.set('anchorCell', cell);
      const columnIndex = this.get('dtApi').column(cell).index();
      this.set('column', this.get('gridOptions').get('columns')[columnIndex]);
      // eslint-disable-next-line no-underscore-dangle
      this.set('model', this.get('dtApi').row(cell).data()._record);
      this.set('value', this.getCurrentValue());
    },

    visible: Ember.computed.notEmpty('model'),

    actions: {
      reset() {
        this.reset();
      },
      submit() {
        this.submit();
      }
    }
  });

  InlineEditComponent.reopenClass({
    positionalParams: ['dtApi', 'gridOptions']
  });

  exports.default = InlineEditComponent;
});