define('oath-ui-components/components/x-component/component', ['exports', 'oath-ui-components/components/x-component/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'x-component',
    layout: _template.default,

    /**
     * @attribute value
     */
    value: null,

    isSimpleValue: Ember.computed('value', function () {
      return typeof this.get('value') !== 'object';
    }).readOnly()
  }).reopenClass({
    positionalParams: ['value']
  });
});