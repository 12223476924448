define('oath-ui-components/components/oa-grid/objects/column', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    frozen: false,
    show: true,
    type: 'string',
    typeFormat: null,
    _totalType: Ember.computed('type', function () {
      const type = this.get('type');
      if (type === 'budgetEdit') {
        return 'currency';
      } else if (type === 'dateEdit') {
        return 'string';
      } else if (type === 'statusToggle') {
        return 'string';
      }
      return type;
    }),
    totalType: Ember.computed.oneWay('_totalType'),
    _totalProperty: Ember.computed('property', function () {
      const property = this.get('property');
      return `total${property[0].toUpperCase()}${property.slice(1)}`;
    }),
    totalProperty: Ember.computed.oneWay('_totalProperty'),
    sortProperty: Ember.computed.oneWay('property'),
    highlightProperty: Ember.computed.oneWay('property')
  });
});