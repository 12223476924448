define('oath-ui-components/components/oa-datatables/inline-progress-bar/component', ['exports', 'oath-ui-components/components/oa-datatables/inline-progress-bar/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const InlineProgressBarComponent = Ember.Component.extend({
    layout: _template.default,

    dtApi: null,
    value: 0,
    maxValue: 100,
    minValue: 0,
    row: null,

    onDtApiChange: Ember.observer('dtApi', function () {
      if (!this.get('dtApi')) {
        return;
      }

      Ember.$(this.get('dtApi').table().node()).on('rowProgress', (event, row, value) => this.updateProgressBar(event, row, value));
    }),

    updateProgressBar(event, row, value) {
      this.set('value', value);
      if (value >= this.get('maxValue')) {
        Ember.$('.oa-datatables--inline-progress-bar').fadeOut(1000, () => {
          this.set('value', 0);
        });
      } else {
        Ember.$('.oa-datatables--inline-progress-bar').show();
        this.set('row', Ember.$(row.node()));
      }
    },

    barPosition: Ember.computed('row', function () {
      return Ember.String.htmlSafe((() => {
        if (!this.get('row')) return '';
        const position = Ember.$(this.get('row')).position();
        const width = Ember.$(this.get('row')).width();
        const height = Ember.$(this.get('row')).height();
        return `top: ${position.top}px; left: ${position.left}px; width: ${width}px; height: ${height}px;`;
      })());
    }),

    willDestroyElement(...args) {
      this._super(...args);

      Ember.$(this.get('dtApi').table().node()).off('rowProgress');
    }
  });

  InlineProgressBarComponent.reopenClass({
    positionalParams: ['dtApi']
  });

  exports.default = InlineProgressBarComponent;
});