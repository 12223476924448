define('oath-ui-components/components/oa-tabs--horizontal/component', ['exports', 'oath-ui-components/components/oa-tabs--base/component', 'oath-ui-components/components/oa-tabs--horizontal/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    tagName: 'ul',
    classNames: 'oa-tabs--horizontal',
    layout: _template.default,

    actions: {
      itemLabel(item) {
        const labelPath = this.get('labelPath');

        if (labelPath) {
          return Ember.get(item, labelPath);
        }

        return item;
      }
    }
  });
});