define('oath-ui-components/components/oa-file/page-object', ['exports', 'ember-cli-page-object/extend', '@ember/test-helpers', 'oath-ui-components/components/page-object'], function (exports, _extend, _testHelpers, _pageObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  class FilePageObject extends _pageObject.default {
    constructor(options = {}) {
      super({
        scope: options.scope || '.oa-file',

        async pickFiles(files) {
          const el = (0, _extend.findElement)(this).find('input[type="file"]');

          await (0, _testHelpers.triggerEvent)(el.get(0), 'change', files.map(({ fileName, fileContent }) => {
            const blob = new Blob([fileContent], {
              type: 'text/plain'
            });
            blob.name = fileName;
            return blob;
          }));
        },

        async dropFiles(files) {
          const el = (0, _extend.findElement)(this);

          await (0, _testHelpers.triggerEvent)(el.get(0), 'dragover');

          (true && !(el.is('.oa-file--drag-over')) && Ember.assert('oa-file drag over not triggered', el.is('.oa-file--drag-over')));


          await (0, _testHelpers.triggerEvent)(el.get(0), 'drop', {
            dataTransfer: {
              files: files.map(({ fileName, fileContent }) => {
                const blob = new Blob([fileContent], {
                  type: 'text/plain'
                });
                blob.name = fileName;
                return blob;
              })
            }
          });

          (true && !(!el.is('.oa-file--drag-over')) && Ember.assert('oa-file drop not triggered', !el.is('.oa-file--drag-over')));
        }
      });
    }
  }
  exports.default = FilePageObject;
});