define('oath-ui-components/components/oa-dropdown/content/subtitle/component', ['exports', 'oath-ui-components/components/oa-dropdown/content/subtitle/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    isSelected: false,
    isActive: false,
    actions: {
      select(value, label, icon) {
        this.sendAction('select', value, label, icon);
      }
    }
  }).reopenClass({
    positionalParams: ['title', 'value']
  });
});