define('oath-ui-components/components/oa-shopping-cart/component', ['exports', 'oath-ui-components/components/oa-shopping-cart/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['oa-shopping-cart'],
    items: Ember.computed(() => []),
    layout: _template.default,
    noItemSelectedLabel: 'No item selected',
    descriptionLabel: 'Description',
    selectedLabel: 'Selected',
    removeAllLabel: 'Remove All',
    totalSelectedLabel: 'Total Selected',
    showToolTips: false
  });
});