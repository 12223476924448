define('oath-ui-components/components/oa-datatables/column', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // pulled from DataTables documentation
  const columnProperties = ['cellType', 'className', 'contentPadding', 'createdCell', 'data', 'defaultContent', 'name', 'orderable', 'orderData', 'orderDataType', 'orderSequence', 'searchable', 'title', 'type', 'visible', 'width', 'inlineEdit'];

  const Column = Ember.Object.extend({
    data: '',
    grid: null,

    renderer: Ember.computed('grid', function () {
      if (this.get('grid')) {
        return this.get('grid').get('renderer');
      }
      return null;
    }),

    createdInlineEditingCell(tdElement, value, row) {
      const cell = tdElement;
      const inlineEdit = this.get('inlineEdit');
      const inlineEditingEnabled = typeof inlineEdit === 'function' ? inlineEdit(value, row) : inlineEdit;

      if (inlineEditingEnabled) {
        cell.className = cell.className.concat(' oa-datatables--inline-edit-cell').trim();
      }
    },

    assignToGrid(grid) {
      this.set('grid', grid);

      if (this.get('inlineEdit')) {
        this.set('createdCell', this.get('createdInlineEditingCell').bind(this));
      }

      if (this.get('component')) {
        this.addClassName('oa-datatables--custom-cell__wrapper');
      }
    },

    addClassName(className) {
      const currentClassName = this.get('className');

      this.set('className', Ember.isEmpty(currentClassName) ? className : currentClassName.concat(` ${className}`));
    },

    cellValue(row) {
      const data = this.get('data');
      if (typeof data === 'function') return data(this.record(row));
      return this.record(row).get(data);
    },

    // if not otherwise defined,
    // fallback on capitalized property name
    title: Ember.computed('data', function () {
      return this.get('data').charAt(0).toUpperCase() + this.get('data').slice(1);
    }),

    processed: Ember.computed(function () {
      // allow for computed properties, explicit null is okay
      const definedProperties = columnProperties.filter(prop => this.get(prop) !== undefined);
      const values = Ember.getProperties(this, definedProperties);
      if (!Ember.isBlank(this.get('wrappedRender'))) {
        values.render = this.get('wrappedRender');
      }
      return values;
    }),

    record(row) {
      // eslint-disable-next-line no-underscore-dangle
      return row._record;
    },

    throttledRender(callback) {
      return {
        display: (data, type, row, settings) => {
          const cell = this.get('grid').get('api').cell(settings.row, settings.col).node();
          return Ember.isBlank(cell.innerHTML) ? callback.call(this, data, type, row) : cell.innerHTML;
        }
      };
    },

    wrappedRender: Ember.computed('render', 'grid', 'component', function () {
      if (!Ember.isBlank(this.get('component')) && Ember.isBlank(this.get('inlineEdit'))) {
        if (Ember.isBlank(this.get('grid'))) return undefined;
        return this.throttledRender((data, type, row) => this.get('grid').renderCellComponent(this.cellValue(row), this.record(row), this));
      }

      if (Ember.isBlank(this.get('render'))) return undefined;
      return this.throttledRender((data, type, row) => this.get('render')(this.cellValue(row), this.record(row)));
    })
  });

  Column.reopenClass({
    actions(actions) {
      return Column.create({
        data: () => '',
        title: '',
        className: 'oa-row-actions',
        width: 45,
        orderable: false,
        component: {
          name: 'component:oa-datatables/row-actions',
          bind(value, row, api) {
            return {
              api,
              row,
              columnActions: actions
            };
          }
        }
      });
    }
  });

  exports.default = Column;
});