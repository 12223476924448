define('oath-ui-components/components/oa-notifications/component', ['exports', 'oath-ui-components/components/oa-notifications/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-notifications'],
    notifications: Ember.A(),

    businessBarNotifications: Ember.computed('notifications.@each', function () {
      return this.get('notifications').filterBy('type', 'business-bar');
    }),

    passiveNotifications: Ember.computed('notifications.@each', function () {
      return this.get('notifications').filterBy('type', 'passive');
    }),

    operativeNotifications: Ember.computed('notifications.@each', function () {
      return this.get('notifications').filterBy('type', 'operative');
    })
  });
});