define('oath-ui-components/components/oa-notifications/base-notification-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['messageType'],

    notification: null,

    messageType: Ember.computed.readOnly('notification.messageType'),

    message: Ember.computed('notification.{message,htmlContent}', function () {
      const message = this.get('notification.message');

      if (this.get('notification.htmlContent')) {
        return Ember.String.htmlSafe(message);
      }

      return message;
    }).readOnly(),

    actions: {
      dismiss() {
        this.get('notifications').remove(this.get('notification'));
      }
    }
  }).reopenClass({
    positionalParams: ['notification']
  });
});