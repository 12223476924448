define('oath-ui-components/components/oa-resizable/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-resizable',

    /**
     * options hash for [jQuery UI resizable](http://api.jqueryui.com/resizable/)
     *
     * @attribute options
     * @default null
     * @type object
     */
    options: null,

    didRender(...args) {
      this._super(...args);

      this.$().resizable(Object.assign({}, this.get('options')));
    },

    willDestroyElement(...args) {
      this._super(...args);
      this.$().resizable('destroy');
    }
  });
});