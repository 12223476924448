define('ember-microstates/index', ['exports', 'ember-microstates/-microstate'], function (exports, _microstate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'MicroState', {
    enumerable: true,
    get: function () {
      return _microstate.default;
    }
  });
});