define('oath-ui-components/components/oa-text/page-object', ['exports', 'ember-cli-page-object/extend', 'ember-cli-page-object/macros', 'ember-cli-page-object', '@ember/test-helpers', 'oath-ui-components/components/page-object'], function (exports, _extend, _macros, _emberCliPageObject, _testHelpers, _pageObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  class TextInputPageObject extends _pageObject.default {
    constructor(options) {
      super(Object.assign({
        scope: '.oa-text',

        prefix: {
          scope: '.oa-text__prefix'
        },

        infix: {
          scope: '.oa-text__infix',

          disabled: (0, _emberCliPageObject.property)('disabled'),
          enabled: (0, _macros.getter)(function () {
            return !this.disabled;
          })
        },

        postfix: {
          scope: '.oa-text__postfix'
        },

        value: (0, _macros.alias)('infix.value'),
        disabled: (0, _macros.alias)('infix.disabled'),
        enabled: (0, _macros.alias)('infix.enabled'),

        async fillIn(content) {
          await (0, _testHelpers.fillIn)((0, _extend.findElementWithAssert)(this.infix).get(0), content);
          await (0, _pageObject.wait)();
        },

        async focus() {
          await (0, _testHelpers.focus)((0, _extend.findElementWithAssert)(this.infix).get(0));
          await (0, _pageObject.wait)();
        },

        async blur() {
          await (0, _testHelpers.blur)((0, _extend.findElementWithAssert)(this.infix).get(0));
          await (0, _pageObject.wait)();
        }
      }, options));
    }
  }
  exports.default = TextInputPageObject;
});