define('oath-ui-components/components/oa-tabs--vertical/group/component', ['exports', 'oath-ui-components/components/oa-tabs--vertical/group/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-tabs--vertical__group',
    layout: _template.default,
    disabled: false,
    model: null,
    eventbus: null
  }).reopenClass({
    positionalParams: ['model']
  });
});