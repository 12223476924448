define('oath-ui-components/components/oa-shopping-cart/item/component', ['exports', 'oath-ui-components/components/oa-shopping-cart/item/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['oa-shopping-cart__item'],
    tooltipPosition: 'top right',
    showOnEllipsis: false,
    actions: {
      mouseEnter() {
        const title = this.$('.oa-shopping-cart__item__title');
        if (this.get('showToolTips') && title[0].scrollWidth > title.innerWidth()) {
          this.set('showOnEllipsis', true);
        }
      },

      remove() {
        this.get('removeOne')(this.get('item'));
      }
    },
    layout: _template.default
  });
});