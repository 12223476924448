define('oath-ui-components/components/oa-tabs--vertical/group/tab/component', ['exports', 'oath-ui-components/components/oa-tabs--vertical/tab-base/component', 'oath-ui-components/components/oa-tabs--vertical/group/tab/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    classNames: 'oa-subtab',
    classNameBindings: ['model.active:oa-subtab--active', 'model.dirty:oa-subtab--dirty'],
    disabled: false,

    clickable: Ember.computed('disabled', 'model.disabled', function () {
      return !this.get('disabled') && !this.get('model.disabled');
    }).readOnly()
  });
});