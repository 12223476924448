define('ember-microstates/helpers/select', ['exports', 'ember-microstates/models/select', 'ember-microstates'], function (exports, _select, _emberMicrostates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberMicrostates.MicroState.extend({

    initialValueFor([values = []], options) {
      let Type = options.multiple ? _select.MultipleSelect : _select.SingleSelect;
      return Type.create(values, options);
    },

    transitions: {
      options: {
        toggle(selection, option) {
          return selection.toggle(option);
        },
        select(selection, option) {
          return selection.toggle(option, true);
        },
        deselect(selection, option) {
          return selection.toggle(option, false);
        }
      }
    }
  });
});