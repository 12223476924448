define('oath-ui-components/components/oa-resize/component', ['exports', 'oath-ui-components/components/oa-resize/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /* global elementResizeDetectorMaker */
  const DETECTOR = elementResizeDetectorMaker({
    strategy: 'scroll'
  });

  exports.default = Ember.Component.extend({
    classNames: 'oa-resize',
    layout: _template.default,

    /**
     * @property width
     */
    width: null,

    /**
     * @property height
     */
    height: null,

    detectors: Ember.computed(() => Object.freeze({
      x: Ember.$('<div class="oa-resize__h-detector"></div>').get(0),
      y: Ember.$('<div class="oa-resize__v-detector"></div>').get(0)
    })).readOnly(),

    measures: Ember.computed(function () {
      return Object.freeze({
        x: Ember.run.bind(this, function (el) {
          if (!this.isDestroying) {
            this.sendAction('on-resize-x', el.getBoundingClientRect().width);
          }
        }),

        y: Ember.run.bind(this, function (el) {
          if (!this.isDestroying) {
            this.sendAction('on-resize-y', el.getBoundingClientRect().height);
          }
        })
      });
    }).readOnly(),

    didInsertElement(...args) {
      this._super(...args);

      const detectors = this.get('detectors');
      const measures = this.get('measures');

      DETECTOR.listenTo(detectors.x, measures.x);
      DETECTOR.listenTo(detectors.y, measures.y);
    },

    didReceiveAttrs(...args) {
      this._super(...args);

      if (!this.get('on-resize-x')) {
        this.set('on-resize-x', width => this.set('width', width));
        this.set('on-resize-y', height => this.set('height', height));
      }
    },

    willDestroyElement(...args) {
      this._super(...args);

      const detectors = this.get('detectors');
      const measures = this.get('measures');

      DETECTOR.removeListener(detectors.x, measures.x);
      DETECTOR.removeListener(detectors.y, measures.y);
    }
  });
});