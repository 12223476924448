define('oath-ui-components/components/oa-icon/component', ['exports', 'oath-ui-components/components/oa-icon/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    layout: _template.default,
    oaIconCache: Ember.inject.service(),
    classNames: 'oa-icon',
    iconNames: null,
    iconNamesNormalized: Ember.computed('iconNames', 'oaIconCache.load.lastSuccessful', function () {
      const map = this.get('oaIconCache.load.lastSuccessful.value');

      const icons = this.get('iconNames').map(iconName => {
        const parts = iconName.match(/^(([-\w]+):)?([-\w]+)(@([\w-]+))?$/);
        const ns = parts[2] || '';
        const mag = parseFloat(parts[5] || '') || 1;
        const name = parts[3];
        const id = `${ns}${ns ? ':' : ''}${name}`;
        const guid = map.get(id);

        (true && !(guid) && Ember.assert(`An icon named ${name} does not exist. Please verify the icon does exist in app/icons/`, guid));

        return {
          ns, name, mag, id, guid
        };
      });

      const max = icons.reduce((accum, { mag }) => Math.max(accum, mag), Number.NEGATIVE_INFINITY);

      icons.forEach(icon => {
        const iconElement = icon;
        iconElement.scale = Number(Number(icon.mag / max).toFixed(3));
      });

      return icons;
    }).readOnly()
  }).reopenClass({
    positionalParams: 'iconNames'
  });
});