define('oath-ui-components/components/oa-range-selector/row/component', ['exports', 'oath-ui-components/components/oa-range-selector/row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    dragToSelectRangeLabel: null,
    clickAndDragToSelectRangeLabel: null,
    rangeName: null, // gets set as the object property name that contains the range array
    classNames: ['oa-range-selector__row'],

    // the states of our checkbox
    checked: false,
    disabled: false,

    // start and end are the endpoints of our range
    start: 0,
    end: 0,

    // preStart and preEnd are used for loading preselctions
    preStart: 0,
    preEnd: 0,

    maxWidth: null, // passed in from top level
    maxWidthClass: Ember.computed('maxWidth', function () {
      const maxWidth = this.get('maxWidth');
      if (maxWidth) {
        return `max-width: ${maxWidth}`;
      }
      return null;
    }),

    optionButtonWidth: null, // passed in from top level
    optionButtonWidthClass: Ember.computed('optionButtonWidth', function () {
      const optionButtonWidth = this.get('optionButtonWidth');
      if (optionButtonWidth) {
        return `width: ${optionButtonWidth}`;
      }
      return null;
    }),

    // selectAllHidden is our state that tracks our initial
    // 'everything is selected but visually only 'All' is checked'
    // also passed in from top level
    selectAllHidden: null,

    range: null, // a single range passed down from parent
    // rangeValuesDetailed adds properties to our range array too maintain state
    rangeValuesDetailed: Ember.computed('range.[]', function () {
      return this.get('range').values.map(val => {
        const formattedNode = Ember.Object.create({
          value: val,
          selected: true, // this will output to our final output selection if true
          selectedVisual: false // this toggles the css class for selected
        });
        return formattedNode;
      });
    }),

    // we bind this component as a property so that we can call selectAllHiddenToggle()
    // from our parent
    didInsertElement() {
      this.set('register-this', this);
      // after inserting- we also trigger our preselections
      this.setProperties({
        start: this.get('preStart'),
        end: this.get('preEnd')
      });
    },

    // called when deciding if we need to select all but display only the all checkmark
    selectAllHiddenToggle() {
      if (this.get('selectAllHidden')) {
        this.selectAllClearAll();
      } else {
        this.clearAll();
      }
    },

    tooltip: Ember.computed('start', 'end', 'isDragging', function () {
      const { start, end, isDragging } = this.getProperties('start', 'end', 'isDragging');

      if (start !== end && isDragging) {
        return `${start} - ${end}`;
      } else if (start === end && isDragging) {
        return this.get('dragToSelectRangeLabel');
      }
      return this.get('clickAndDragToSelectRangeLabel');
    }),

    // this does a lot- basically, given a start and an end point,
    // we figure out all the states we need to apply
    startEndObserver: Ember.observer('start', 'end', function () {
      const { rangeValuesDetailed, start, end } = this.getProperties('rangeValuesDetailed', 'start', 'end');
      let isSelectingRange = false;

      // this zips through and selects all boxes within the start and end
      rangeValuesDetailed.forEach(obj => {
        // case if we have ony one box selected
        if (obj.value === start && obj.value === end) {
          obj.setProperties({
            selected: true,
            selectedVisual: true
          });
          // case where we're selecting boxes on the way to the end
        } else if (isSelectingRange && obj.value !== end) {
          obj.setProperties({
            selected: true,
            selectedVisual: true
          });
          // case if we're selecting first box in range
        } else if (obj.value === start) {
          isSelectingRange = true;
          obj.setProperties({
            selected: true,
            selectedVisual: true
          });
          // case if we're selecting last box in selected range
        } else if (obj.value === end && isSelectingRange) {
          isSelectingRange = false;
          obj.setProperties({
            selected: true,
            selectedVisual: true
          });
          // case for unselected boxes
        } else {
          obj.setProperties({
            selected: false,
            selectedVisual: false
          });
        }
      });

      const selection = [];

      rangeValuesDetailed.forEach(obj => {
        if (obj.get('selected')) {
          selection.push(obj.get('value'));
        }
      });

      // this if block decides if the entire row is selected
      if (start === rangeValuesDetailed[0].value && end === rangeValuesDetailed[rangeValuesDetailed.length - 1].value) {
        this.set('allSelected', true);
        this.set('checked', true);
      } else {
        this.set('allSelected', false);
        this.set('checked', false);
      }

      // relays our selection to the parent component for processing
      this.sendAction('setSelection', this.get('rangeName'), selection);

      // updates our rangeValuesDetailed
      this.set('rangeValuesDetailed', rangeValuesDetailed);
    }),

    isDragging: false,

    mouseLeave() {
      // if our mouse leaves this row while still dragging,
      // we'll just set the 'end' to the end of the range
      if (this.get('isDragging')) {
        const range = this.get('range');
        this.setProperties({
          isDragging: false,
          end: range.values[range.values.length - 1]
        });
      }
    },

    selectAll() {
      const rangeValuesDetailed = this.get('rangeValuesDetailed');
      this.setProperties({
        start: rangeValuesDetailed[0].get('value'),
        end: rangeValuesDetailed[rangeValuesDetailed.length - 1].get('value')
      });
    },

    clearAll() {
      this.setProperties({
        start: null,
        end: null,
        checked: false,
        allSelected: false
      });
    },

    // this is when we want to select all, but visually none will be selected
    selectAllClearAll() {
      const rangeValuesDetailed = this.get('rangeValuesDetailed');
      this.setProperties({
        start: rangeValuesDetailed[0].get('value'),
        end: rangeValuesDetailed[rangeValuesDetailed.length - 1].get('value')
      });

      // after our start and end select all, we go back and uncheck our visual indicators
      rangeValuesDetailed.forEach(obj => {
        obj.setProperties({
          selectedVisual: false
        });
      });
      this.setProperties({
        checked: false
      });
    },

    _setStart(val) {
      this.setProperties({
        start: val,
        end: val,
        isDragging: true
      });
    },

    actions: {
      setStart(val) {
        // this if resets our state if we're coming from a selectAllHidden
        if (this.get('selectAllHidden')) {
          this.sendAction('toggleAll');
        }
        // after the system resets, we then want set a new start and end
        Ember.run.next(this, '_setStart', val);
      },

      setEnd(val, dragging) {
        // when we mouse up, or drag, we find
        // out where we are in our range and update the start or end value
        const rangeValuesDetailed = this.get('rangeValuesDetailed');
        const startIndex = rangeValuesDetailed.findIndex(element => element.value === this.get('start'));
        const currentIndex = rangeValuesDetailed.findIndex(element => element.value === val);

        // our start and end could be dragged in either direction
        if (currentIndex > startIndex) {
          this.set('end', val);
        } else {
          this.set('start', val);
        }

        if (!dragging) {
          this.set('isDragging', false);
        }
      },

      // called when clicking our row specific checkbox
      toggleSelection() {
        const { checked, selectAllHidden } = this.getProperties('checked', 'selectAllHidden');

        // deselect our all button and clear selection
        if (selectAllHidden) {
          this.sendAction('toggleAll');
        }

        // after selectAllHidden cleaning takes place, we want to update our row
        Ember.run.next(() => {
          if (checked) {
            this.clearAll();
            this.setProperties({
              checked: false
            });
          } else {
            this.selectAll();
            this.setProperties({
              checked: true
            });
          }
        });
      }
    }
  });
});