define('oath-ui-components/components/oa-list-builder/list/component', ['exports', 'oath-ui-components/components/oa-list-builder/list/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-list-builder__list'],
    classNameBindings: ['isScrollable:oa-list-builder__list__scrollable', 'isSimple:oa-list-builder__list__simple'],

    customAction: null,

    actions: {
      remove(item) {
        const customAction = this.get('customAction');

        if (customAction) {
          customAction(item);
        } else {
          this.get('list').removeObject(item);
        }
      }
    }
  });
});