define('oath-ui-components/components/oa-left-nav/content/component', ['exports', 'oath-ui-components/components/oa-left-nav/content/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-left-nav__content',
    layout: _template.default
  });
});