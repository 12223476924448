define('oath-ui-components/utils/computed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.construct = construct;


  /* eslint-disable import/prefer-default-export */
  function construct(Constructor, ...args) {
    return Ember.computed(() => new Constructor(...args));
  }
});