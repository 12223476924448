define("ember-microstates/utils/is-primitive", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isPrimitive;
  function isPrimitive(value) {
    if (value == null) {
      return true;
    } else {
      let type = typeof value;
      return type != "object" && type != "function";
    }
  }
});