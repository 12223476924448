define('oath-ui-components/components/oa-grid/search/component', ['exports', 'oath-ui-components/components/oa-grid/search/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: 'oa-grid__search',
    editing: false,

    actions: {
      clearSearch() {
        this.set('rawKeyword', '');
        this.set('editing', false);
      },

      apply() {
        this.set('editing', false);
      },

      editSearch() {
        this.set('editing', true);
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.$('input').focus();
        });
      }
    }
  });
});