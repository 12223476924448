define('oath-ui-components/components/oa-notifications/services/notifications', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ArrayProxy.extend({
    content: Ember.A(),
    log: Ember.A(),

    defaultOptions: Ember.computed({
      get() {
        return {
          autoClear: true,
          clearPrevious: false,
          htmlContent: false,
          messageType: 'info',
          timeout: 2000,
          type: 'passive',
          log: true
        };
      }
    }).readOnly(),

    add(options) {
      if (!options.message) {
        return null;
      }

      if (options.clearPrevious) {
        this.clear();
      }

      const notification = Object.assign({}, this.get('defaultOptions'), options);

      if (notification.log) {
        this.addToLog(options);
      }

      this.pushObject(notification);

      if (notification.autoClear) {
        this.createAsAutoClear(notification);
      }

      // we need to return a reference
      return notification;
    },

    remove(notification) {
      if (!notification) {
        return;
      }

      this.removeObject(notification);
    },

    addToLog(options) {
      const notification = Ember.getProperties(options, 'message', 'title', 'messageType', 'htmlContent');

      notification.timestamp = notification.timestamp || (0, _moment.default)().format('LLL');

      this.get('log').pushObject(notification);
    },

    removeFromLog(notification) {
      this.get('log').removeObject(notification);
    },

    clearLog() {
      this.get('log').clear();
    },

    info(message, options) {
      return this.add(Object.assign({
        message,
        messageType: 'info'
      }, options));
    },

    success(message, options) {
      return this.add(Object.assign({
        message,
        messageType: 'success'
      }, options));
    },

    warning(message, options) {
      return this.add(Object.assign({
        message,
        messageType: 'warning'
      }, options));
    },

    error(message, options) {
      return this.add(Object.assign({
        message,
        messageType: 'error'
      }, options));
    },

    createAsAutoClear(notification) {
      Ember.run.later(this, () => {
        if (this.includes(notification)) {
          this.removeObject(notification);
        }
      }, notification.timeout);
    }
  }).reopenClass({
    isServiceFactory: true
  });
});