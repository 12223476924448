define('oath-ui-components/components/oa-dd-upload/component', ['exports', 'ember-data', 'lodash', 'oath-ui-components/components/oa-dd-upload/template'], function (exports, _emberData, _lodash, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: ['oa-dd-upload'],

    successHandler: null,

    pendingHandler: null,

    validExtensions: [],

    maxFileUploadCount: 1000,

    maxLineCount: null,

    dragNDropFileLabel: 'Drag & Drop your file here',

    clickToBrowseLabel: 'Click to Browse',

    appendAllFiles(inputFile) {
      return _emberData.default.PromiseArray.create({
        promise: new Ember.RSVP.Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', ({ target: { result: data } }) => {
            resolve(data);
          });
          reader.addEventListener('error', reject);
          reader.readAsText(inputFile);
        }).then(response => {
          const returnVal = [response];
          return returnVal;
        })
      });
    },

    readFilesContent(files) {
      Ember.RSVP.Promise.all(files.map(file => this.appendAllFiles(file.content))).then(responses => {
        const invalidLineCountFiles = responses.filter(file => !this.validLineCountFilter(file)).map((response, i) => files.objectAt(i).content.name);

        if (!_lodash.default.isEmpty(invalidLineCountFiles)) {
          this.sendAction('invalidLineCountHandler', invalidLineCountFiles);
        } else {
          const content = responses.map((response, i) => ({
            fileName: files.objectAt(i).content.name,
            data: response
          }));

          this.sendAction('successHandler', { data: content });
        }
      });
    },

    validateUploadFileExtensions(files) {
      if (_lodash.default.isEmpty(this.validExtensions)) {
        return files;
      }

      const validExtensionFiles = files.filter(file => this.validExtensionFilter(file));
      const invalidExtensionFiles = files.filter(file => !this.validExtensionFilter(file));

      if (!_lodash.default.isEmpty(invalidExtensionFiles)) {
        this.sendAction('invalidFileExtensionHandler', {
          uploadedFiles: files.map(file => file.content.name),
          invalidatedFiles: invalidExtensionFiles.map(file => file.content.name)
        });
      }
      return validExtensionFiles;
    },

    validFileUploadCountFilter(uploadedFileAmount) {
      return uploadedFileAmount > 0 && uploadedFileAmount <= this.maxFileUploadCount;
    },

    validExtensionFilter(file) {
      return this.validExtensions.some(extension => file.content.name.toLowerCase().endsWith(extension.toLowerCase()));
    },

    validLineCountFilter(file) {
      return this.maxLineCount === null ? true : file[0].length > 0 && file[0].split('\n').length <= this.maxLineCount;
    },

    actions: {
      uploadFiles(uploaded) {
        this.sendAction('pendingHandler', true);

        const uploadedFileAmount = uploaded.get('length');
        if (this.validFileUploadCountFilter(uploadedFileAmount)) {
          const validUploadedFiles = this.validateUploadFileExtensions(uploaded);
          if (!_lodash.default.isEmpty(validUploadedFiles)) {
            this.readFilesContent(validUploadedFiles);
          }
        } else {
          this.sendAction('invalidFileUploadCountHandler', uploadedFileAmount);
        }
      }
    }
  });
});