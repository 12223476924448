define('oath-ui-components/components/oa-checkbox/component', ['exports', 'oath-ui-components/components/oa-toggle-base/component', 'oath-ui-components/components/oa-checkbox/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    classNames: 'oa-checkbox',

    label: null,

    inputElement: Ember.computed(function () {
      return this.element.querySelector('.oa-checkbox__input');
    }).readOnly()
  }).reopenClass({
    positionalParams: ['value']
  });
});