define('oath-ui-components/components/oa-radio/component', ['exports', 'oath-ui-components/components/oa-radio/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: 'form-check',
    name: null,
    value: null,
    label: null,
    disabled: false,

    click() {
      if (this.get('disabled')) return;
      this.set('selectedValue', this.get('value'));
    },

    didRender(...args) {
      this._super(...args);
      this.updateCheckValue();
    },

    onValueChange: Ember.observer('selectedValue', 'value', function () {
      this.updateCheckValue();
    }),

    updateCheckValue() {
      this.$('input').prop('checked', this.get('value') === this.get('selectedValue'));
    }
  }).reopenClass({
    positionalParams: ['value', 'selectedValue', 'label']
  });
});