define('oath-ui-components/components/oa-grid/tr/component', ['exports', 'oath-ui-components/utils/run', 'oath-ui-components/components/oa-grid/tr/template'], function (exports, _run, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-tr',
    classNameBindings: ['isOdd:oa-tr--odd', 'isEven:oa-tr--even'],
    attributeBindings: ['index:data-row-index'],
    layout: _template.default,

    model: null,
    index: null,

    isOdd: Ember.computed('index', function () {
      return Boolean(this.get('index') % 2);
    }).readOnly(),

    isEven: Ember.computed.not('isOdd').readOnly(),

    didInsertElement(...args) {
      this._super(...args);

      this.selection();
    },

    selection: (0, _run.observerOnceIn)('afterRender', 'model.$meta.selection', function () {
      if (this.get('model.$meta.selection')) {
        this.$().addClass('oa-tr--selected');
      } else {
        this.$().removeClass('oa-tr--selected');
      }
    })

  });
});