define('oath-ui-components/components/oa-burger-menu/component', ['exports', 'oath-ui-components/components/oa-burger-menu/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    icon: 'menu',
    classNames: 'oa-burger-menu',
    classNameBindings: ['menuOpen:oa-burger-menu--active'],
    menuOpen: false,
    actions: {
      triggerToggle() {
        this.set('menuOpen', !this.get('menuOpen'));
      },
      focusOut() {
        if (this.get('menuOpen')) {
          this.set('menuOpen', false);
        }
      }
    }
  });
});