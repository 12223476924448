define('oath-ui-components/components/oa-select-multi/component', ['exports', 'ember-power-select/components/power-select-multiple'], function (exports, _powerSelectMultiple) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _powerSelectMultiple.default.extend({
    searchEnabled: false,
    classNames: 'oa-select-multi'
  });
});