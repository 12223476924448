define('oath-ui-components/components/oa-tabs--base/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    from: null,
    tabs: Ember.computed.alias('from'),

    actions: {
      selectTab(item) {
        this.set('value', item);

        this.sendAction('on-tab-change', item);
      }
    }
  }).reopenClass({
    positionalParams: ['value']
  });
});