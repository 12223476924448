define('oath-ui-components/utils/run', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.observerOnce = observerOnce;
  exports.observerOnceIn = observerOnceIn;
  exports.scheduleOnce = scheduleOnce;
  exports.schedule = schedule;
  function observerOnce(...args) {
    const fn = args.pop();
    const keys = args;

    return Ember.observer(...keys, function () {
      Ember.run.once(this, fn);
    });
  }

  function observerOnceIn(...args) {
    const queue = args.shift();
    const fn = args.pop();
    const keys = args;

    return Ember.observer(...keys, function () {
      Ember.run.scheduleOnce(queue, this, fn);
    });
  }

  function scheduleOnce(...args) {
    const callback = args.pop();
    const queue = args.pop() || 'actions';

    return function method() {
      // eslint-disable-next-line prefer-rest-params
      Ember.run.schedule(queue, this, callback, arguments);
    };
  }

  function schedule(...args) {
    const callback = args.pop();
    const queue = args.pop() || 'actions';

    return function method() {
      // eslint-disable-next-line prefer-rest-params
      Ember.run.scheduleOnce(queue, this, callback, arguments);
    };
  }
});