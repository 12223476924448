define('oath-ui-components/components/oa-range-selector--dropdown/component', ['exports', 'oath-ui-components/components/oa-range-selector--dropdown/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-range-selector--dropdown'],
    checked: false,
    disabled: false,
    ranges: null,
    singleMode: Ember.computed.equal('ranges.length', 1),
    allLabel: 'All',
    canToggleAllOff: true,
    events: null,

    init(...args) {
      this._super(...args);

      this.normalize();

      // https://paultavares.wordpress.com/2015/06/29/use-jquery-to-create-a-private-event-bus-for-inter-component-communication/
      this.set('events', Ember.$({}));

      this.get('events').on('change', () => this.sendAction('on-change'));
    },

    didDestroyElement() {
      this.get('events').off('change');
      this.set('events', null);
    },

    normalize() {
      const ranges = this.get('ranges');

      let all = ranges.every(range => Ember.get(range, 'options').every(option => Ember.get(option, 'checked')));

      if (this.get('singleMode')) {
        const range = ranges[0];
        const options = Ember.A(Ember.get(range, 'options'));

        const lower = Ember.get(options, 'firstObject');
        const upper = Ember.get(options, 'lastObject');

        if (Ember.get(lower, 'checked') && Ember.get(upper, 'checked')) {
          all = true;
        }
      }

      if (all) {
        this.set('checked', true);

        ranges.forEach(range => Ember.get(range, 'options').forEach(option => Ember.set(option, 'checked', true)));
      } else {
        ranges.forEach(range => Ember.get(range, 'options').forEach(option => Ember.set(option, 'checked', Ember.get(option, 'checked') || false)));
      }
    },

    actions: {
      toggleAll() {
        const all = this.get('checked');

        this.get('ranges').forEach(range => Ember.get(range, 'options').forEach(option => Ember.set(option, 'checked', all)));

        Ember.run.next(() => this.get('events').trigger('change'));
      },

      checkToggle(event) {
        const all = this.get('checked');

        if (all && !this.get('canToggleAllOff')) {
          event.preventDefault();
          event.stopPropagation();

          return false;
        }

        return true;
      }
    }
  }).reopenClass({
    positionalParams: ['ranges']
  });
});