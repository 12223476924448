define('oath-ui-components/components/oa-left-nav/sidebar/component', ['exports', 'oath-ui-components/components/oa-left-nav/sidebar/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-left-nav__sidebar',
    layout: _template.default,
    show: true,
    controllers: [],
    actions: {
      toggle() {
        const show = !this.get('show');
        this.set('show', show);
        this.get('controllers').forEach(controller => {
          Ember.set(controller, 'show', show);
        });
      }
    }
  });
});