define('oath-ui-components/components/oa-button/page-object', ['exports', 'ember-cli-page-object', 'ember-cli-page-object/extend', 'ember-cli-page-object/macros', 'ember-native-dom-helpers', 'oath-ui-components/components/page-object'], function (exports, _emberCliPageObject, _extend, _macros, _emberNativeDomHelpers, _pageObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  class ButtonPageObject extends _pageObject.default {
    constructor(options = {}) {
      const { scope } = options;

      super({
        scope: scope || '.oa-button',

        prefix: {
          scope: '.oa-button__prefix'
        },

        infix: {
          scope: '.oa-button__infix'
        },

        postfix: {
          scope: '.oa-button__postfix'
        },

        disabled: (0, _emberCliPageObject.property)('disabled'),

        enabled: (0, _macros.getter)(function () {
          return !this.disabled;
        }),

        async focus() {
          await (0, _emberNativeDomHelpers.focus)((0, _extend.findElementWithAssert)(this).get(0));
          await (0, _pageObject.wait)();
        },

        async blur() {
          await (0, _emberNativeDomHelpers.blur)((0, _extend.findElementWithAssert)(this).get(0));
          await (0, _pageObject.wait)();
        }
      });
    }
  }
  exports.default = ButtonPageObject;
});