define('oath-ui-components/components/oa-date-picker/component', ['exports', 'moment', 'oath-ui-components/components/oa-date-picker/template'], function (exports, _moment, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    oaIconCache: Ember.inject.service(),

    // component options
    classNames: ['oa-date-picker-container'],
    attributeBindings: ['start', 'end', 'serverFormat'],
    placeholder: null,
    inputClass: 'form-control',
    datePickerIconsIds: null,

    inputClasses: Ember.computed('inputClass', function () {
      return `oa-date-picker-input ${this.get('inputClass') || ''}`;
    }),

    isSingleColumnRanges: Ember.computed('ranges', function () {
      const maxSingleColumnRanges = 6;
      const rangesCount = Object.keys(this.get('ranges') || {}).length;

      return rangesCount && rangesCount <= maxSingleColumnRanges;
    }),

    // date-picker options
    start: null,
    end: null,
    minDate: null,
    maxDate: null,
    timePicker: false,
    timePicker24Hour: false,
    timePickerSeconds: false,
    timePickerIncrement: 15,
    showWeekNumbers: false,
    showDropdowns: false,
    linkedCalendars: false,
    dateLimit: false,
    format: 'MMM D, YYYY',
    serverFormat: 'YYYY-MM-DD',
    opens: 'center',
    drops: null,
    separator: ' - ',
    singleDatePicker: false,
    buttonClasses: ['btn'],
    applyClass: null,
    cancelClass: null,
    ranges: null,
    daysOfWeek: _moment.default.weekdaysShort(),
    monthNames: _moment.default.months(),
    cancelLabel: 'Cancel',
    applyLabel: 'Apply',
    customRangeLabel: 'Custom Range',
    showCustomRangeLabel: false,
    fromLabel: 'From',
    toLabel: 'To',
    startTimeLabel: 'Start Time',
    endTimeLabel: 'End Time',
    autoUpdateInput: true,
    autoApply: false,
    alwaysShowCalendars: true,
    firstDay: 0,
    isInvalidDate: null,
    isCustomDate: null,
    autoApplyRanges: false,
    allowOverlappingCalendars: false,
    revertOnCancel: true,
    simplePresets: false,
    parentElement: null,
    attachToContainer: false,

    // date-picker actions
    applyAction: null,
    cancelAction: null,

    didInsertElement(...args) {
      this._super(...args);
      this.initDatePicker();
    },

    didUpdateAttrs(...args) {
      this._super(...args);
      this.initDatePicker();
    },

    prepareDate(date) {
      const momentDate = (0, _moment.default)(date, this.get('serverFormat'));
      return momentDate.isValid() ? momentDate : undefined;
    },

    getParentElement() {
      return this.get('parentElement') || (this.get('attachToContainer') ? `#${this.get('elementId')} .oa-date-picker__input-wrapper` : null);
    },

    getOptions() {
      const startDate = this.prepareDate(this.get('start'));
      const endDate = this.prepareDate(this.get('end'));
      const minDate = this.prepareDate(this.get('minDate'));
      const maxDate = this.prepareDate(this.get('maxDate'));

      const showCustomRangeLabel = this.get('showCustomRangeLabel');

      const options = this.getProperties('isInvalidDate', 'isCustomDate', 'alwaysShowCalendars', 'allowOverlappingCalendars', 'autoUpdateInput', 'autoApply', 'buttonClasses', 'applyClass', 'cancelClass', 'singleDatePicker', 'drops', 'opens', 'timePicker24Hour', 'timePickerSeconds', 'timePickerIncrement', 'showWeekNumbers', 'showDropdowns', 'showCustomRangeLabel', 'linkedCalendars', 'dateLimit', 'autoApplyRanges', 'revertOnCancel');

      options.combinedTimePicker = this.timePicker;

      options.parentEl = this.getParentElement();

      const simplePresets = this.get('simplePresets');

      if (simplePresets) {
        Ember.setProperties(options, {
          autoApplyRanges: true,
          alwaysShowCalendars: false
        });
      }

      const localeOptions = this.getProperties('applyLabel', 'cancelLabel', 'customRangeLabel', 'fromLabel', 'toLabel', 'format', 'firstDay', 'daysOfWeek', 'monthNames', 'separator', 'startTimeLabel', 'endTimeLabel');

      const defaultOptions = {
        locale: localeOptions,
        showCustomRangeLabel,
        startDate,
        endDate,
        minDate,
        maxDate
      };

      if (!this.get('singleDatePicker')) {
        options.ranges = this.get('ranges');
      }

      return Object.assign(options, defaultOptions);
    },

    actions: {
      openCalendar() {
        this.$('.oa-date-picker-input').trigger('click');
      }
    },

    getDatePicker() {
      return this.$('.oa-date-picker-input').data('daterangepicker');
    },

    initDatePicker() {
      Ember.run.scheduleOnce('afterRender', () => {
        this.$('.oa-date-picker-input').daterangepicker(this.getOptions());
        this.attachDatePickerClasses();
        this.loadIconCache();
        this.bindEvents();
      });
    },

    attachDatePickerClasses() {
      const $datePicker = this.getDatePicker().container;

      $datePicker.addClass('oa-date-picker');

      if (!this.get('ranges') && !this.get('singleDatePicker')) {
        $datePicker.addClass('no-ranges');
      }

      if (this.get('isSingleColumnRanges')) {
        $datePicker.addClass('single-column-ranges');
      }

      if (this.get('simplePresets')) {
        $datePicker.addClass('simple-presets');
      }
    },

    loadIconCache() {
      return this.get('oaIconCache.load.last').then(icons => this.set('datePickerIconsIds', {
        prev: icons.get('chevron-left'),
        next: icons.get('chevron-right'),
        down: icons.get('chevron-down')
      }));
    },

    // TODO: expose actions, to have an ability to handle them outside of component
    bindEvents() {
      this.$('.oa-date-picker-input').on('calendarsUpdated.daterangepicker', () => {
        this.appendIcons();
      });
      this.$('.oa-date-picker-input').on('apply.daterangepicker', (e, datePicker) => {
        this.set('start', datePicker.startDate);
        this.set('end', datePicker.endDate);
        this.handleDatePickerEvent('applyAction', datePicker);
      });
      this.$('.oa-date-picker-input').on('cancel.daterangepicker', (e, datePicker) => {
        this.handleDatePickerEvent('cancelAction', datePicker);
      });
    },

    handleDatePickerEvent(actionName, datePicker) {
      const action = this.get(actionName);

      if (action) {
        this.sendAction(actionName, datePicker.startDate, datePicker.endDate, datePicker);
      }
    },

    appendIcons() {
      this.appendIcon('prev', '.calendar-table .prev');
      this.appendIcon('next', '.calendar-table .next');
      this.appendIcon('down', '.combined-calendar-time .time-dropdown');
    },

    // TODO: move to separate service
    appendIcon(iconType, selector) {
      const iconId = this.get('datePickerIconsIds')[iconType];

      this.getDatePicker().container.find(selector).append(`<svg class="oa-${iconType}-icon"><use xlink:href="#${iconId}"></use></svg>`);
    },

    willDestroyElement(...args) {
      this._super(...args);

      this.getDatePicker().container.remove();
    }
  });
});