define('oath-ui-components/components/oa-reveal/component', ['exports', 'ember-concurrency', 'oath-ui-components/components/oa-reveal/template'], function (exports, _emberConcurrency, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-reveal',
    attributeBindings: 'method:data-trigger-method',
    layout: _template.default,

    /**
     * @attribute position
     */
    position: null,

    /**
     * @attribute title
     */
    title: null,

    /**
     * @attribute method
     */
    method: 'hover',

    /**
     * @attribute reveal
     */
    reveal: Ember.computed({
      get() {
        return false;
      },

      set(key, value) {
        this.get('eventTask').perform();

        return value;
      }
    }),

    /**
     * @event on-trigger
     */
    'on-trigger': null,

    positionNormalized: Ember.computed('position', function () {
      const defaults = {
        of: this.$('.ui-graph__html__pin__anchor, .oa-reveal__trigger').last()
      };

      return Object.assign(defaults, this.get('position'));
    }).volatile().readOnly(),

    init(...args) {
      this._super(...args);

      const trigger = this.get('on-trigger');

      if (!trigger) {
        this.set('on-trigger', reveal => {
          this.set('reveal', reveal);
        });
      }
    },

    didInsertElement(...args) {
      this._super(...args);

      this.get('eventTask').perform();
    },

    willDestroyElement(...args) {
      this._super(...args);

      this.get('eventTask').cancelAll();
    },

    revealTrigger(value) {
      if (this.get('reveal') !== value) {
        this.sendAction('on-trigger', value);
      }
    },

    eventTask: (0, _emberConcurrency.task)(function* () {
      const method = this.get('method');

      const hover = this.get('hoverTask');
      const focus = this.get('focusTask');
      const click = this.get('clickTask');

      yield (0, _emberConcurrency.waitForQueue)('afterRender');

      switch (method) {
        case 'hover':
          yield hover.perform();break;
        case 'click':
          yield click.perform();break;
        case 'focus':
          yield focus.perform();break;
        default:
          break;
      }
    }).restartable(),

    hoverTask: (0, _emberConcurrency.task)(function* () {
      const element = this.$('.oa-reveal__trigger').get(0);

      while (!this.isDestroying) {
        const { target } = yield (0, _emberConcurrency.waitForEvent)(Ember.$(document), 'mouseover');

        this.revealTrigger(element === target || element.contains(target));
      }
    }),

    clickTask: (0, _emberConcurrency.task)(function* () {
      const element = this.$('.oa-reveal__trigger').get(0);

      while (!this.isDestroying) {
        const { target } = yield (0, _emberConcurrency.waitForEvent)(this, 'click');

        if (element === target || element.contains(target)) {
          this.revealTrigger(!this.get('reveal'));
        }
      }
    }),

    focusTask: (0, _emberConcurrency.task)(function* () {
      yield Ember.RSVP.all([this.get('focusInTask').perform(), this.get('focusOutTask').perform()]);
    }),

    focusInTask: (0, _emberConcurrency.task)(function* () {
      while (!this.isDestroying) {
        yield (0, _emberConcurrency.waitForEvent)(this.$('.oa-reveal__trigger'), 'focusin');

        this.revealTrigger(true);
      }
    }),

    focusOutTask: (0, _emberConcurrency.task)(function* () {
      while (!this.isDestroying) {
        yield (0, _emberConcurrency.waitForEvent)(this.$('.oa-reveal__trigger'), 'focusout');

        this.revealTrigger(false);
      }
    })
  }).reopenClass({
    positionalParams: ['title']
  });
});