define('ember-microstates/utils/object-utils', ['exports', 'ember-microstates/utils/assign'], function (exports, _assign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mapObject = mapObject;
  exports.reduceObject = reduceObject;
  exports.eachProperty = eachProperty;


  /**
   * Maps over the keys of an object converting the values of those keys into new
   * objects. The return value will be an object with the same set of
   * keys, but a different set of values. E.g.
   *
   * > mapObject({first: 1, second: 2}, (value)=> value *2)
   *
   *   {first: 2, second: 4}
   */
  function mapObject(object, fn) {
    return reduceObject(object, function (result, name, value) {
      return (0, _assign.default)(result, { [name]: fn(name, value) });
    });
  }

  function reduceObject(object, fn, result = {}) {
    eachProperty(object, function (name, value) {
      result = fn(result, name, value);
    });
    return result;
  }

  function eachProperty(object, fn) {
    if (typeof object === 'object') {
      Object.keys(object).forEach(function (name) {
        fn(name, object[name]);
      });
    }
  }
});