define('oath-ui-components/components/oa-backdrop/component', ['exports', 'oath-ui-components/components/oa-backdrop/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'oa-backdrop',
    layout: _template.default,

    didInsertElement() {
      this._super(...arguments); // eslint-disable-line prefer-rest-params

      // Fixes Mobile Safari Touch
      this.$().on('click', evt => {
        if (evt.target === this.element) {
          Ember.run(this, 'sendAction', 'on-click');
        }
      });
    },

    willDestroyElement() {
      this._super(...arguments); // eslint-disable-line prefer-rest-params

      this.$().off('click');
    }
  });
});