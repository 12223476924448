define('oath-ui-components/components/oa-selection-boxes/component', ['exports', 'oath-ui-components/components/oa-selection-boxes/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-selection-boxes'],
    options: [],
    disabled: false,
    masterChecked: Ember.computed('options.@each.checked', function () {
      const options = this.get('options');
      const optionsCount = options.length;
      const checkedCount = options.filter(option => Ember.get(option, 'checked')).length;

      return checkedCount === optionsCount;
    }),
    actions: {
      masterStateClick() {
        if (!this.get('disabled')) {
          // Clicking on "All" always selects everything (visually shown unchecked)
          const options = this.get('options');

          options.forEach(option => Ember.set(option, 'checked', true));
        }
      },
      groupItemClick(index) {
        if (!this.get('disabled')) {
          const masterChecked = this.get('masterChecked');
          const options = this.get('options');
          if (masterChecked) {
            // Switch from all checked (visually only "All") to single clicked checked
            options.forEach((option, id) => Ember.set(option, 'checked', id === index));
          } else {
            Ember.set(options[index], 'checked', !Ember.get(options[index], 'checked'));
          }
        }
      }
    }

  }).reopenClass({
    positionalParams: ['options']
  });
});