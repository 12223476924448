define('oath-ui-components/components/oa-dropdown/component', ['exports', 'oath-ui-components/components/oa-dropdown/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    selectOneLabel: 'Select one...',
    noResultsFoundLabel: 'No results found.',
    classNames: ['oa-dropdown'],
    isOpen: false,
    noResults: Ember.computed.equal('filteredResults.length', 0),
    icon: '',

    /**
     * Selected is an array that stores the information of what has been selected.
     * By default this is left empty, but it can be populated to pre-select items
     * in the dropdown if you would like to create default options.
     *
     * @attribute selected
     */
    selected: [],

    /**
     * Contents is an array that either takes a list of strings, or a list of
     * objects in order to include groups. Hidden from the user, a list of strings
     * will be converted to a list of objects in one group with no label.
     *
     * This is specified on the oa-dropdown component.
     * OBJECT FORMAT :
     *
     * {
     *  label: 'Dropdown Group Title 1',
     *  contents: [
     *  {
     *     id: 1,
     *     value: 'Lorem ipsum',
     *     icon: 'favorite'
     *   }, {
     *     id: 2,
     *     value: 'dolor sit amet',
     *     icon: 'mail'
     *   }]
     * }
     *  @attribute contents
     */
    contents: [],

    /**
     * headerLabel defines the label for the dropdown.
     * If no headerLabel is present, nothing will be shown here.
     *
     * This is specified on the oa-dropdown component.
     * @attribute headerLabel
     */
    headerLabel: '',

    /**
     * selectedLabel defines the label for the selected item in the dropdown.
     * If no selectedLabel is present, the value will be shown here.
     *
     * This is specified on the oa-dropdown component.
     * @attribute selectedLabel
     */
    selectedLabel: '',
    searchTerm: '',
    triggerComponent: 'oa-dropdown/trigger',
    contentComponent: 'oa-dropdown/content',

    init(...args) {
      this._super(...args);

      Ember.deprecate('oa-dropdown is deprecated. Please use oa-select or oa-select-multi instead.', false, {
        id: 'oath-ui-components.oa-dropdown',
        until: '4.0',
        url: 'https://git.ouroath.com/pages/ember/oath-ui-components/master/docs/components/oa-select'
      });
    },

    actions: {
      toggle() {
        if (this.$().find('input').val()) {
          this.$().find('input').select();
        }
        this.toggleProperty('isOpen');
      },
      expand() {
        this.set('isOpen', true);
      },
      select(value, label, icon) {
        this.toggleProperty('isOpen');
        this.set('selected', value);
        this.set('selectedLabel', label);
        this.set('searchTerm', label || value);
        this.set('icon', icon);
        document.activeElement.blur();
      },
      filter(searchTerm) {
        if (Ember.typeOf(this.contents[0]) === 'string') {
          const filteredResults = this.contents.filter(search => search.toLowerCase().includes(searchTerm.toLowerCase()));
          this.set('filteredResults', filteredResults);
        } else {
          const filteredResults = Object.values(this.contents).map(groups => {
            const { label, contents } = groups;
            const values = Object.values(contents);
            const searchResults = values.filter(item => item.value.toLowerCase().includes(searchTerm.toLowerCase()));
            return { label, contents: searchResults };
          }).filter(result => result.contents.length !== 0);
          this.set('filteredResults', filteredResults);
        }
      }
    },
    focusOut() {
      this.set('isOpen', false);
      this.set('searchTerm', this.get('selectedLabel') || this.selected);
      this.set('filteredResults', this.contents);
    }
  });
});