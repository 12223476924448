define('oath-ui-components/utils/ddau', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sendEventAction = sendEventAction;
  /* eslint-disable import/prefer-default-export */
  async function sendEventAction(context, actionName, valueName, updatedValue) {
    if (context.isDestroying) {
      return;
    }

    const action = context.get(actionName);

    context.rerender();

    if (typeof action === 'function') {
      const newValue = await action(updatedValue);

      if (context.isDestroying) {
        return;
      }

      context.set(valueName, typeof newValue !== 'undefined' ? newValue : updatedValue);

      return;
    }

    context.set(valueName, updatedValue);

    context.sendAction(actionName, updatedValue);
  }
});