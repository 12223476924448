define('oath-ui-components/components/page-object', ['exports', 'ember-test-helpers/wait', 'ember-native-dom-helpers', '@ember/test-helpers', 'ember-cli-page-object', 'ember-cli-page-object/-private/dsl', 'ember-cli-page-object/-private/execution_context/integration', 'ember-cli-page-object/-private/execution_context/acceptance', 'ember-native-dom-helpers/-private/get-element'], function (exports, _wait, _emberNativeDomHelpers, _testHelpers, _emberCliPageObject, _dsl, _integration, _acceptance, module) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.wait = undefined;


  (function patch() {
    try {
      // eslint-disable-line global-require
      const code = module.default.toString();

      if (!code.match('SVGElement')) {
        // need patching
        const patched = module.default.toString().replace('selectorOrElement instanceof HTMLElement', `
        selectorOrElement instanceof Window ||
        selectorOrElement instanceof Document ||
        selectorOrElement instanceof HTMLElement ||
        selectorOrElement instanceof SVGElement
      `);

        module.default = Function('_settings', `return ${patched};`)({ default: _emberNativeDomHelpers.settings }); // eslint-disable-line no-new-func
      }
    } finally {
      // do nothing if module not found
    }
  })();

  function waitForFrames(_count = 2) {
    const timestamps = [];
    let count = _count;

    return new Ember.RSVP.Promise(resolve => {
      if (!count) {
        resolve(timestamps);
      }

      requestAnimationFrame(function frame(timestamp) {
        timestamps.push(timestamp);

        count -= 1;

        if (count) {
          requestAnimationFrame(frame);
        } else {
          resolve(timestamps);
        }
      });
    });
  }

  function waiter(context, onResolve, onReject) {
    const fn = window.wait || _wait.default;

    return fn().then(value => waitForFrames().then(() => {
      if (onResolve) {
        return onResolve.call(context, value);
      }

      return null;
    }), reason => waitForFrames().then(() => {
      if (onReject) {
        return onReject.call(context, reason);
      }
      throw reason;
    }));
  }

  function unwrap(selector, _container) {
    let container = _container;
    if (container instanceof Ember.$) {
      container = container.get(0);
    }

    if (typeof container === 'undefined') {
      container = document.getElementById('ember-testing');
    }

    const element = Ember.$(selector, container).get(0);

    if (element instanceof SVGElement) {
      if (!element.id) {
        element.id = Ember.generateGuid();
      }

      return `#${element.id}`;
    }

    return element;
  }

  _dsl.default.then.value = function (onResolve, onReject) {
    return waiter(this, onResolve, onReject);
  };

  _integration.default.prototype.click = function (selector, container) {
    return (0, _testHelpers.click)(unwrap(this.$(selector, container)));
  };

  _acceptance.default.prototype.click = function (selector, container) {
    return (0, _testHelpers.click)(unwrap(selector, container));
  };

  const wait = exports.wait = waiter;

  function descriptorFix(_node) {
    const node = _node;
    delete node.render;
    delete node.setContext;
    delete node.removeContext;

    const descriptors = Object.getOwnPropertyDescriptors(node);

    Object.keys(descriptors).forEach(key => {
      const descriptor = descriptors[key];

      // eslint-disable-next-line no-underscore-dangle
      if (descriptor.enumerable && descriptor.value && descriptor.value.__parentTreeNode) {
        Object.defineProperty(node[key], '__parentTreeNode', {
          value: node,
          enumerable: false
        });

        descriptorFix(node[key]);
      }
    });

    return node;
  }

  /**
   * Creates a page-object that can be used in both integration
   * and acceptance test.
   *
   * Usage:
   *
   *   import PageObject from '../page-object';
   *
   *   export default class TablePageObject {
   *     constructor(options) {
   *       // give super a valid ember-cli-page-object spec object
   *       super({
   *         scope: options.scope || '.ui-table--v2' // remember to give it an default scope
   *       });
   *     };
   *   });
   * @class PageObject
   * @param {object} options - configurable class options
   */
  class PageObject {
    constructor(options = {}) {
      const ctor = this.constructor;
      const descriptors = Object.getOwnPropertyDescriptors((0, _emberCliPageObject.create)(options));

      Object.defineProperties(this, {
        isDescriptor: {
          value: true
        },

        setup: {
          value() {
            const value = Object.getOwnPropertyDescriptor(this, 'value');

            Object.defineProperties(this, {
              value: {
                configurable: true,
                value: false
              },

              get: {
                configurable: true,
                value() {
                  const getDescriptors = Object.getOwnPropertyDescriptors((0, _emberCliPageObject.create)(options, {
                    parent: this
                  }));

                  Object.defineProperty(this, 'value', value);

                  return descriptorFix(Object.defineProperties(Object.create(ctor.prototype), getDescriptors)); // eslint-disable-line max-len
                }
              }
            });
          }
        }
      });

      Object.defineProperties(this, descriptors);

      descriptorFix(this);
    }

    get context() {
      // eslint-disable-line class-methods-use-this
      return Object.freeze({
        $(_selector) {
          let selector = _selector;
          if (typeof selector === 'string') {
            selector = `${_emberNativeDomHelpers.settings.rootElement} ${selector}`;
          }

          return Ember.$(selector);
        }
      });
    }
  }
  exports.default = PageObject;
});