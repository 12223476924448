define('oath-ui-components/components/oa-tabs--vertical/tab-base/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    model: null,
    collapse: false,
    eventbus: null,

    didInsertElement(...args) {
      this._super(...args);

      this.get('eventbus').on('tab-change', this, this.onTabChange);
    },

    willDestroyElement(...args) {
      this._super(...args);

      this.get('eventbus').off('tab-change', this, this.onTabChange);
    },

    onTabChange(tab) {
      if (tab !== this) {
        this.set('model.active', false);
      }
    },

    actions: {
      activate() {
        this.get('eventbus').trigger('tab-change', this, this.get('model'));
      }
    }
  }).reopenClass({
    positionalParams: ['model']
  });
});