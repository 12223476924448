define('oath-ui-components/components/oa-global-navigation/component', ['exports', 'oath-ui-components/components/oa-global-navigation/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    navigationName: '',
    classNames: 'global-navigation-bar',
    layout: _template.default,
    logo: 'logo'
  }).reopenClass({
    positionalParams: 'iconAction'
  });
});