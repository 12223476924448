define('oath-ui-components/components/oa-modal/component', ['exports', 'oath-ui-components/components/oa-modal/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function array() {
    return Ember.computed({
      get(key) {
        Ember.assert(`oa-modal#${key} must be set`);
      },

      set(key, value) {
        if (!Ember.isArray(value)) {
          return [value];
        }

        return value;
      }
    });
  }

  exports.default = Ember.Component.extend({
    classNames: ['oa-modal'],
    layout: _template.default,
    header: array(),
    body: array(),
    footer: array()
  }).reopenClass({
    positionalParams: ['header']
  });
});