define('oath-ui-components/components/oa-dropdown/content/component', ['exports', 'oath-ui-components/components/oa-dropdown/content/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    groups: false,
    groupComponent: 'oa-dropdown/content/group',
    itemComponent: 'oa-dropdown/content/item',
    subtitleComponent: 'oa-dropdown/content/subtitle',
    actions: {
      select(value, label, icon) {
        this.sendAction('select', value, label, icon);
      }
    }
  });
});