define('oath-ui-components/components/oa-dropdown/trigger/component', ['exports', 'oath-ui-components/components/oa-dropdown/trigger/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    /**
     * Typedown boolean determines if the typedown feature will be available for
     * the list of options. Typedown allows a user to type and filter the list
     * of results as they type.
     *
     * This is defined on the oa-dropdown/content component.
     * @attribute typedown
     */
    typedown: false,
    actions: {
      toggle() {
        this.sendAction('toggle');
      },
      filter(value) {
        this.sendAction('filter', value);
      },
      expand() {
        this.sendAction('expand');
      },
      highlight() {
        this.$().find('input').select();
      }
    }
  }).reopenClass({
    positionalParams: ['disabled']
  });
});