define('oath-ui-components/components/oa-grid/filter/component', ['exports', 'oath-ui-components/components/oa-grid/filter/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: 'oa-grid__filter',
    // a filter is a combination of a "column" plus a "logic" (operator) plus a "value".
    // conjunctions join filters (and/or).

    filterText: '', // text for the filter Pill
    filterOpen: false,
    appliedFilter: false, // true after the user hits 'enter' and the filter was valid
    errorMessage: '', // custom error message
    showingDropdown: true, // true if we should be showing the dropdown

    parsedInput: {
      onColumn: true, // true if input is empty, a column prefix, or we can't parse. column can be empty
      onLogic: false, // true if input has verifyable completed column (cannot be a column name that is a prefix of another column name). logic can be empty
      onValue: false, // true if input contains verifyable completed column and verifyable completed logic. value can be empty
      columnPrefix: '',
      logicPrefix: '',
      valuePrefix: '',
      unparseable: false // true if the block the user is on cannot be parsed as a prefix of or full word from that block
    },

    // choices that appear in the dropdown as the user types
    dropdownChoices: Ember.computed('errorMessage', 'parsedInput', 'filterSpecs.[]', function () {
      // don't show anything if there is an error message
      if (this.get('errorMessage')) {
        return [];
      }

      const columnPrefix = this.get('parsedInput').columnPrefix.toLowerCase().trim();
      const logicPrefix = this.get('parsedInput').logicPrefix.toLowerCase().trim();
      const {
        onColumn,
        onLogic,
        unparseable,
        onValue
      } = this.get('parsedInput');

      if (unparseable && (onLogic || onColumn)) {
        return [];
      } else {
        if (onColumn) {
          // user is typing in a column name, so show the truncated column dropdown menu
          const validColumns = this.getOptions(this.get('filterSpecs'), true);
          const truncatedColumnOptions = validColumns.filter(choice => choice.toLowerCase().startsWith(columnPrefix));
          // convert options back, in case the user is inputting an alias
          return this.convertAliasesToOptions(this.get('filterSpecs'), truncatedColumnOptions);
        } else if (onLogic) {
          // user is typing in a valid logic, so show the truncated logic dropdown menu
          const columnOption = this.convertAliasToOption(columnPrefix, this.get('filterSpecs')).toLowerCase();
          const columnSpecs = this.findSpecsGivenOption(columnOption, this.get('filterSpecs'));
          const validLogic = this.getOptions(columnSpecs.operators, true);
          const truncatedLogicOptions = validLogic.filter(choice => choice.toLowerCase().startsWith(logicPrefix));
          // convert options back, in case the user is inputting an alias
          return this.convertAliasesToOptions(columnSpecs.operators, truncatedLogicOptions);
        } else if (onValue) {
          // for values, we only worry about parsing the value after the user hits enter, so just always show the whole dropdown
          const columnOption = this.convertAliasToOption(columnPrefix, this.get('filterSpecs')).toLowerCase();
          const columnSpecs = this.findSpecsGivenOption(columnOption, this.get('filterSpecs'));
          const logicOption = this.convertAliasToOption(logicPrefix, columnSpecs.operators).toLowerCase();
          const logicSpecs = this.findSpecsGivenOption(logicOption, columnSpecs.operators);
          let validValues = columnSpecs.suggestions;
          if (logicSpecs.suggestions) {
            validValues = logicSpecs.suggestions;
          }
          return validValues;
        } else {
          return [];
        }
      }
    }),

    // things that we want to show in the dropdown, but don't want the user to be able to click on
    dropdownMessages: Ember.computed('errorMessage', 'parsedInput', 'filterSpecs.[]', function () {
      // don't show anything if there is an error message
      if (this.get('errorMessage')) {
        return [];
      }

      const columnPrefix = this.get('parsedInput').columnPrefix.toLowerCase().trim();
      const logicPrefix = this.get('parsedInput').logicPrefix.toLowerCase().trim();

      if (this.get('parsedInput').onValue) {
        const columnOption = this.convertAliasToOption(columnPrefix, this.get('filterSpecs')).toLowerCase();
        const columnSpecs = this.findSpecsGivenOption(columnOption, this.get('filterSpecs'));
        const logicOption = this.convertAliasToOption(logicPrefix, columnSpecs.operators).toLowerCase();
        const logicSpecs = this.findSpecsGivenOption(logicOption, columnSpecs.operators);
        let validValueMessages = columnSpecs.messages;
        if (logicSpecs.messages) {
          validValueMessages = logicSpecs.messages;
        }
        return validValueMessages;
      }

      return [];
    }),

    // FUNCTIONS

    // for adding a word to the filter upon clicking in the dropdown (or hitting enter when there is only one item in the dropdown)
    addWordToFilter(word) {
      let parsed = this.parse(this.$('input')[0].value);
      if (parsed.onColumn) {
        this.$('input')[0].value = word.concat(' ');
      } else if (parsed.onLogic) {
        this.$('input')[0].value = parsed.columnPrefix.concat(' ').concat(word).concat(' ');
      } else if (parsed.onValue) {
        this.$('input')[0].value = parsed.columnPrefix.concat(' ').concat(parsed.logicPrefix).concat(' ').concat(word);
      }
      parsed = this.parse(this.$('input')[0].value);
      this.set('parsedInput', parsed);

      this.$('input')[0].scrollLeft = this.$('input')[0].scrollWidth;
      this.$('input')[0].focus();
    },

    areTheSamePhrase(phraseOne, phraseTwo) {
      return phraseOne.toLowerCase().trim() === phraseTwo.toLowerCase().trim();
    },

    // closed filter and erases all filter information
    clearFilter() {
      this.set('filterOpen', false);
      this.set('parsedInput', {
        onColumn: true,
        onLogic: false,
        onValue: false,
        columnPrefix: '',
        logicPrefix: '',
        valuePrefix: '',
        unparseable: false
      });
      this.$('input').removeClass('oa-grid__filter--error');
      this.set('appliedFilter', false);
      this.set('filterText', '');
      this.set('errorMessage', '');
      this.set('chosenFilter', undefined);
    },

    // given a list of aliases and options, as well the parentList of which the members of that list are children of,
    // return a list of the options to which those aliases and options correspond (no repeats)
    convertAliasesToOptions(parentList, listOfToBeConverted) {
      return Array.from(new Set(listOfToBeConverted.map(item => this.convertAliasToOption(item, parentList))));
    },

    // given an alias or option, as well the parentList of which that word is a child of,
    // return the options to which that alias or option corresponds
    convertAliasToOption(potentialAlias, parentList) {
      const foundItem = parentList.find(parentItem => {
        if (parentItem.option.toLowerCase() === potentialAlias.toLowerCase()) {
          return true;
        } else {
          if (parentItem.aliases) {
            return !!parentItem.aliases.find(alias => alias.toLowerCase().includes(potentialAlias.toLowerCase()));
          } else {
            return false;
          }
        }
      });

      return foundItem && foundItem.option ? foundItem.option : false;
    },

    // given the option name, as well as the list of items that the object with that option name is a member of,
    // return the object that corresponds to that option name
    // option MUST BE an option, not an alias. If you have an alias, use convertAliasToOption() first
    findSpecsGivenOption(option, parentList) {
      return parentList.find(parentItem => parentItem.option.toLowerCase() === option.toLowerCase());
    },

    // gets the final filter function, with the user's value input applied, for the controller that contains this filter compnent to use
    getFilter() {
      const parsed = this.get('parsedInput');
      const column = this.convertAliasToOption(parsed.columnPrefix, this.get('filterSpecs'));
      const columnSpecs = this.findSpecsGivenOption(column, this.get('filterSpecs'));
      const logic = this.convertAliasToOption(parsed.logicPrefix, columnSpecs.operators);
      const logicSpecs = this.findSpecsGivenOption(logic, columnSpecs.operators);
      const filterFunction = logicSpecs.filter;

      const { property } = columnSpecs;
      let value = parsed.valuePrefix.trim().toLowerCase();

      if (logicSpecs.valuePreprocessor) {
        value = logicSpecs.valuePreprocessor(value);
      } else if (columnSpecs.valuePreprocessor) {
        value = columnSpecs.valuePreprocessor(value);
      }

      let propertyPreprocessor = enteredProperty => enteredProperty;
      if (logicSpecs.propertyPreprocessor) {
        ({ propertyPreprocessor } = logicSpecs);
      } else if (columnSpecs.propertyPreprocessor) {
        ({ propertyPreprocessor } = columnSpecs);
      }

      return {
        filterFunction: filterFunction.bind(null, value, property, propertyPreprocessor),
        column: column.toLowerCase()
      };
    },

    // gets all options in parentList. If includeAliases, returns all options and aliases in the parentList
    getOptions(parentList, includeAliases) {
      return parentList.reduce((previousValue, item) => {
        const aliases = includeAliases && item.aliases ? [...item.aliases] : [];
        return [...previousValue, item.option, ...aliases];
      }, []);
    },

    // returns a list (subset of listOfPhrases) of words for which partialPhrase is a prefix
    getPhrasesOfWhichPartialPhraseIsAPrefix(partialPhrase, listOfPhrases) {
      return listOfPhrases.filter(phrase => phrase.toLowerCase().trim().startsWith(partialPhrase.toLowerCase().trim()));
    },

    // return true if one of the phrases in listOhPhrases matches the phrase exactly (except whitespace and case)
    hasFullPhraseMatch(phrase, listOfPhrases) {
      return listOfPhrases.some(singlePhrase => this.areTheSamePhrase(phrase, singlePhrase));
    },

    parse(text) {
      // try to parse the text into a filter
      // if empty, it's like we're typing a column name in
      const parsed = {
        // onColumn, onLogic, and onValue tell us what dropdown to be showing
        onColumn: false, // true if input is empty, a column prefix, or we can't parse. column can be empty
        onLogic: false, // true if input has verifyable completed column (cannot be a column name that is a prefix of another column name). logic can be empty
        onValue: false, // true if input contains verifyable completed column and verifyable completed logic. value can be empty
        columnPrefix: '',
        logicPrefix: '',
        valuePrefix: '',
        unparseable: false // true if the block the user is on cannot be parsed as a prefix of or full word from that block
      };

      if (!text) {
        parsed.onColumn = true;
        return parsed;
      }

      // replace all whitespace with a single space and remove leading whitespace
      let remainingText = text.replace(/\s+/g, ' ').replace(/^\s+/, '');

      if (!remainingText) {
        parsed.onColumn = true;
        return parsed;
      }

      let splitText = remainingText.trim().split(' ');
      if (splitText.length === 0 || splitText.length === 1 && !splitText[0]) {
        // if there are no splits or there is only one split that is the empty string
        parsed.onColumn = true;
        return parsed;
      }

      // find the COLUMN
      const validColumns = this.getOptions(this.get('filterSpecs'), true);

      // check for the longest possible column prefix
      let lastSplitIndex = splitText.length;
      let potentialColumnPrefix = splitText.slice(0, lastSplitIndex).join(' ');
      let columnMatches;
      while (!columnMatches) {
        const matches = this.getPhrasesOfWhichPartialPhraseIsAPrefix(potentialColumnPrefix, validColumns);
        if (matches.length > 0) {
          // we have a column prefix!
          columnMatches = matches;
        } else if (lastSplitIndex <= 1) {
          // we went through all of splitText and didn't find a column prefix;
          // this means that the input is just bad.
          // so we show the column dropdown and the text in red. Done.
          parsed.onColumn = true;
          parsed.columnPrefix = remainingText;
          parsed.unparseable = true;
          return parsed;
        } else {
          // no column prefix: try a different split
          lastSplitIndex -= 1;
          potentialColumnPrefix = splitText.slice(0, lastSplitIndex).join(' ');
        }
      }

      // so at this point, we have a valid column prefix that matches something in the list of validColumns
      // the prefix is potentialColumnPrefix; it is a prefix to the validColumns in matches

      const sawFullColumnName = this.hasFullPhraseMatch(potentialColumnPrefix, columnMatches);
      if (!sawFullColumnName) {
        // we only have the prefix of a column name.
        // if the columnprefix is the only text in the user input, continue to show truncated column dropdown
        // else, it's actually not valid: show entire column dropdown
        if (potentialColumnPrefix === remainingText.trim()) {
          parsed.onColumn = true;
          parsed.columnPrefix = remainingText;
          return parsed;
        }
        parsed.onColumn = true;
        parsed.columnPrefix = remainingText;
        parsed.unparseable = true;
        return parsed;
      }

      // we have a full column name! called potentialColumnPrefix
      // if there is a space after the name, show logic dropdown
      // else (no space, aka the end of the input): continue to show truncated column dropdown
      if (!(remainingText[potentialColumnPrefix.length] === ' ')) {
        parsed.onColumn = true;
        parsed.columnPrefix = remainingText;
        return parsed;
      }

      parsed.columnPrefix = potentialColumnPrefix;

      // on to LOGIC!

      // remove column name from the remaining text; remove leading whitespace
      remainingText = remainingText.slice(potentialColumnPrefix.length).replace(/^\s+/, '');
      if (!remainingText) {
        parsed.onLogic = true;
        return parsed;
      }

      splitText = remainingText.trim().split(' ');
      if (splitText.length === 0 || splitText.length === 1 && !splitText[0]) {
        // if there are no splits or there is only one split that is the empty string
        parsed.onLogic = true;
        return parsed;
      }

      const columnOption = this.convertAliasToOption(parsed.columnPrefix, this.get('filterSpecs')).toLowerCase();
      const columnSpecs = this.findSpecsGivenOption(columnOption, this.get('filterSpecs'));
      const validLogic = this.getOptions(columnSpecs.operators, true);

      // check for the longest possible logic prefix
      lastSplitIndex = splitText.length;
      let potentialLogicPrefix = splitText.slice(0, lastSplitIndex).join(' ');
      let logicMatches;
      while (!logicMatches) {
        const matches = this.getPhrasesOfWhichPartialPhraseIsAPrefix(potentialLogicPrefix, validLogic);
        if (matches.length > 0) {
          // we have a logic prefix!
          logicMatches = matches;
        } else if (lastSplitIndex <= 1) {
          // we went through all of splitText and didn't find a logic prefix;
          // this means that the input is just bad.
          // so we show the logic dropdown, column text in black, and the logic text in red.
          parsed.onLogic = true;
          parsed.logicPrefix = remainingText;
          parsed.unparseable = true;
          return parsed;
        } else {
          // no logic prefix: try a different split
          lastSplitIndex -= 1;
          potentialLogicPrefix = splitText.slice(0, lastSplitIndex).join(' ');
        }
      }

      // so at this point, we have a valid column, and a partial or full logic that matches something in the list of validLogic
      // the prefix is potentialLogicPrefix; it is a prefix to the validLogic in matches

      const sawFullLogicName = this.hasFullPhraseMatch(potentialLogicPrefix, logicMatches);
      if (!sawFullLogicName) {
        // we have the prefix of a logic name.
        // if the logicprefix is the only text in the user input, continue to show truncated logic dropdown
        // else, it's actually not valid: show entire logic dropdown, text in red
        if (potentialLogicPrefix === remainingText.trim()) {
          parsed.onLogic = true;
          parsed.logicPrefix = remainingText;
          return parsed;
        }
        parsed.onLogic = true;
        parsed.logicPrefix = remainingText;
        parsed.unparseable = true;
        return parsed;
      }

      // we have a full column and logic name! called potentialLogicPrefix
      // if there is a space after the logic name, show value dropdown
      // else (no space, aka the end of the input): continue to show truncated logic dropdown
      if (!(remainingText[potentialLogicPrefix.length] === ' ')) {
        parsed.onLogic = true;
        parsed.logicPrefix = remainingText;
        return parsed;
      }

      parsed.logicPrefix = potentialLogicPrefix;

      // on to the VALUE!
      remainingText = remainingText.slice(potentialLogicPrefix.length).replace(/^\s+/, '').trim();
      if (!remainingText) {
        parsed.onValue = true;
        return parsed;
      }

      splitText = remainingText.trim().split(' ');
      if (splitText.length === 0 || splitText.length === 1 && !splitText[0]) {
        // if there are no splits or there is only one split that is the empty string
        parsed.onValue = true;
        return parsed;
      }

      const logicOption = this.convertAliasToOption(parsed.logicPrefix, columnSpecs.operators).toLowerCase();
      const logicSpecs = this.findSpecsGivenOption(logicOption, columnSpecs.operators);
      let validValueRegex = columnSpecs.expectedValue;
      if (logicSpecs.expectedValue) {
        validValueRegex = logicSpecs.expectedValue;
      }

      // at this point, we have a full valid column and logic, followed by a space
      // everything else is a value; we don't look for value prefixes,
      // just show the full value dropdown
      // check if the value matches the regex to see if it is a full valid value

      parsed.onValue = true;
      parsed.valuePrefix = remainingText;
      parsed.unparseable = !validValueRegex.test(remainingText);

      // finally, check if the entire filter works together as a filter?

      return parsed;
    },

    actions: {
      expandFilter() {
        this.set('filterOpen', true);
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.$('input')[0].focus();
        });
      },

      clear() {
        this.clearFilter();
      },

      letterEntered() {
        // everytime the user enters a letter, parse the input and react
        this.set('errorMessage', '');
        const parsed = this.parse(this.$('input')[0].value);
        this.set('parsedInput', parsed);
        // if we are not on the value, and there is something unparseable (a bad column or logic), turn it red
        if (parsed.unparseable && parsed.onColumn) {
          this.set('errorMessage', 'Invalid Column: "'.concat(parsed.columnPrefix).concat('"'));
          this.$('input').addClass('oa-grid__filter--error');
        } else if (parsed.unparseable && parsed.onLogic) {
          this.set('errorMessage', 'Invalid Operator: "'.concat(parsed.logicPrefix).concat('"'));
          this.$('input').addClass('oa-grid__filter--error');
        } else {
          this.$('input').removeClass('oa-grid__filter--error');
        }
      },

      entered() {
        // if there's no text when the user hits enter, just close the filter
        if (!this.$('input')[0].value.trim()) {
          this.clearFilter();
          return;
        }

        // otherwise, parse the input to see if it's valid
        const parsed = this.parse(this.$('input')[0].value);
        this.set('parsedInput', parsed);
        // check if they are choosing the single item in the dropdown
        if (!parsed.onValue && this.get('dropdownChoices').length === 1) {
          this.addWordToFilter(this.get('dropdownChoices')[0]);
          return;
        }
        if (parsed.unparseable) {
          if (parsed.onColumn) {
            this.set('errorMessage', 'Invalid Column: "'.concat(parsed.columnPrefix).concat('"'));
          } else if (parsed.onLogic) {
            this.set('errorMessage', 'Invalid Operator: "'.concat(parsed.logicPrefix).concat('"'));
          } else if (parsed.onValue) {
            this.set('errorMessage', 'Invalid Value: "'.concat(parsed.valuePrefix).concat('"'));
          } else {
            this.set('errorMessage', 'Unparseable Filter');
          }
        } else if (parsed.onValue) {
          // the user has entered an entire valid filter; try filtering for real
          if (!parsed.valuePrefix.toLowerCase().trim()) {
            // check to make sure value is non-empty
            this.set('errorMessage', 'Incomplete Filter: missing value');
            return;
          }
          this.set('appliedFilter', true);
          this.set('filterText', this.$('input')[0].value);
          this.set('chosenFilter', this.getFilter());
        } else if (parsed.onLogic) {
          this.set('errorMessage', 'Incomplete Filter: missing operator');
        } else if (parsed.onColumn) {
          this.set('errorMessage', 'Incomplete Filter: missing column');
        } else {
          this.set('errorMessage', 'Incomplete Filter');
        }
      },

      clickDropdown(choice) {
        this.addWordToFilter(choice);
      },

      reopenFilter() {
        this.set('appliedFilter', false);
        const parsed = this.parse(this.get('filterText'));
        this.set('parsedInput', parsed);
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.$('input')[0].value = this.get('filterText');
          this.set('filterText', '');
          this.$('input')[0].focus();
        });
      },

      showDropdown() {
        this.set('showingDropdown', true);
      },

      hideDropdown() {
        this.set('showingDropdown', false);
      }

    }

  });
});