define('oath-ui-components/components/oa-modal--dialog/component', ['exports', 'oath-ui-components/components/oa-modal--dialog/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function array() {
    return Ember.computed({
      get(key) {
        Ember.assert(`oa-modal--dialog#${key} must be set`);
      },

      set(key, value) {
        if (!Ember.isArray(value)) {
          return [value];
        }

        return value;
      }
    });
  }

  exports.default = Ember.Component.extend({
    classNames: ['oa-modal', 'oa-modal--dialog'],
    layout: _template.default,
    header: array(),
    body: array(),
    footer: array(),

    init(...args) {
      this._super(...args);

      Ember.deprecate('oa-modal--dialog is deprecated. Please use oa-modal instead.', false, {
        id: 'oath-ui-components.oa-modal--dialog',
        until: '4.0',
        url: 'https://git.ouroath.com/pages/ember/oath-ui-components/master/docs/components/oa-modal'
      });
    }
  }).reopenClass({
    positionalParams: ['header']
  });
});