define('oath-ui-components/components/oa-progress-bar/bar/component', ['exports', 'oath-ui-components/components/oa-progress-bar/bar/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['progress-bar'],
    classNameBindings: ['progressBarStriped', 'progressBarAnimated', 'typeClass'],
    attributeBindings: ['style', 'ariaValueNow', 'ariaValueMin', 'ariaValueMax'],
    minValue: 0,
    maxValue: 100,
    value: 0,
    showLabel: false,
    striped: false,
    animate: false,

    progressBarStriped: Ember.computed.readOnly('striped'),
    progressBarAnimated: Ember.computed.readOnly('animate'),

    typeClass: Ember.computed('value', function () {
      if (this.get('value') >= this.get('maxValue')) {
        return 'bg-success';
      }
      return 'bg-default';
    }),

    ariaValueNow: Ember.computed.readOnly('value'),
    ariaValueMin: Ember.computed.readOnly('minValue'),
    ariaValueMax: Ember.computed.readOnly('maxValue'),

    percent: Ember.computed('value', 'minValue', 'maxValue', function () {
      const value = parseFloat(this.get('value'));
      const minValue = parseFloat(this.get('minValue'));
      const maxValue = parseFloat(this.get('maxValue'));

      return Math.min(Math.max((value - minValue) / (maxValue - minValue), 0), 1) * 100;
    }).readOnly(),

    percentRounded: Ember.computed('percent', function () {
      return Math.round(this.get('percent'));
    }).readOnly(),

    style: Ember.computed('percent', function () {
      const percent = this.get('percent');
      return Ember.String.htmlSafe(`width: ${percent}%`);
    })

  });
});