define('oath-ui-components/components/oa-tabs--vertical/tab/component', ['exports', 'oath-ui-components/components/oa-tabs--vertical/tab-base/component', 'oath-ui-components/components/oa-tabs--vertical/tab/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: ['oa-tab'],
    classNameBindings: ['active:oa-tab--active', 'model.disabled:oa-tab--disabled', 'dirty:oa-tab--dirty'],
    layout: _template.default,

    clickable: Ember.computed.not('model.disabled'),

    active: Ember.computed('model.active', 'model.tabs.@each.active', function () {
      return this.get('model.active') || Ember.A(this.get('model.tabs')).filter(tab => tab.active).length > 0;
    }),

    dirty: Ember.computed('model.dirty', 'model.tabs.@each.dirty', function () {
      return this.get('model.dirty') || Ember.A(this.get('model.tabs')).filter(tab => tab.dirty).length > 0;
    })
  });
});