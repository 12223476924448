define('oath-ui-components/components/oa-button/component', ['exports', 'oath-ui-components/utils/ddau', 'oath-ui-components/utils/run', 'oath-ui-components/components/oa-button/template'], function (exports, _ddau, _run, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: 'oa-button',
    classNameBindings: ['isFocused:oa-button--focus', 'isBlurred:oa-button--blur', 'disabled:oa-button--disabled:oa-button--enabled'],
    attributeBindings: ['disabled', 'title', 'type'],
    layout: _template.default,

    /**
     * @attribute type
     */
    type: 'button',

    /**
     * @attribute label
     */
    label: null,

    /**
     * @attribute value
     */
    value: Ember.computed.oneWay('label'),

    /**
     * @attribute value
     */
    disabled: null,

    /**
     * @attribute shouldBubbleClick
     */
    shouldBubbleClick: false,

    isFocused: false,
    isBlurred: true,

    didRender(...args) {
      this._super(...args);

      this.element.value = this.get('value');
    },

    focusIn: (0, _run.scheduleOnce)(function (...args) {
      this._super(...args);

      this.set('isFocused', true);
      this.set('isBlurred', false);

      (0, _ddau.sendEventAction)(this, 'on-focus-in', 'value', this.get('value'));
    }),

    focusOut: (0, _run.scheduleOnce)(function (...args) {
      this._super(...args);

      this.set('isFocused', false);
      this.set('isBlurred', true);

      (0, _ddau.sendEventAction)(this, 'on-focus-out', 'value', this.get('value'));
    }),

    click(event) {
      const target = Ember.$(event.target);

      // if target is a button but not this button, then do nothing
      if (target.is('.oa-button') && !target.is(this.element)) {
        return;
      }

      (0, _ddau.sendEventAction)(this, 'on-click', 'value', this.get('value'));
    }
  }).reopenClass({
    positionalParams: ['label', 'value']
  });
});