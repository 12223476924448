define('oath-ui-components/components/oa-group-creation/item-actions/component', ['exports', 'oath-ui-components/components/oa-group-creation/item-actions/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    isIncluded: Ember.computed('includedData.@each', 'item', function () {
      return this.get('includedData').includes(this.get('item'));
    }),

    isExcluded: Ember.computed('excludedData.@each', 'item', function () {
      return this.get('excludedData') ? this.get('excludedData').includes(this.get('item')) : false;
    }),

    isDisabled: Ember.computed('isIncluded', 'isExcluded', 'item', function () {
      return this.get('item').get('isDisabled') || this.get('isIncluded') || this.get('isExcluded');
    }),

    isReadOnly: Ember.computed.alias('item.isReadOnly'),

    actions: {
      addItem(data, item) {
        if (!(item.get('isReadOnly') || item.get('isDisabled') || data.includes(item))) {
          data.pushObject(item);
        }
      },

      removeItem(data, item) {
        if (!(item.get('isReadOnly') || item.get('isDisabled')) && data.includes(item)) {
          data.removeObject(item);
        }
      }
    }
  });
});