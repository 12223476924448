define('oath-ui-components/components/oa-grid/component', ['exports', 'oath-ui-components/computed/tree', 'oath-ui-components/components/oa-grid/template'], function (exports, _tree, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-grid'],
    columns: Ember.computed(() => []),
    data: Ember.computed(() => []),
    properties: Ember.computed(() => {}),
    tree: (0, _tree.default)('data')
  });
});