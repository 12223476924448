define('oath-ui-components/components/oa-list-builder/component', ['exports', 'oath-ui-components/components/oa-grid/objects/column', 'oath-ui-components/components/oa-list-builder/template'], function (exports, _column, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-list-builder'],

    columns: Ember.computed(() => []),
    data: Ember.computed(() => []),
    isScrollable: false,
    isSimple: false,
    tooltip: null,

    columnDefinition: Ember.computed('columns', function () {
      const columns = this.get('columns').map(column => {
        const columnProperties = Ember.assign({ frozen: true }, column);

        if (column.isEditable) {
          columnProperties.className = 'editable';
        }

        if (column.isLocked) {
          columnProperties.className = 'locked';
          delete columnProperties.component;
        }

        return _column.default.create(columnProperties);
      });

      columns.push(_column.default.create({
        labelComponent: '',
        component: 'remove',
        frozen: true,
        width: 50
      }));

      return columns;
    }),

    list: Ember.computed('data.[]', function () {
      return Ember.A(this.get('data'));
    })
  });
});