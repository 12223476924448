define('oath-ui-components/components/oa-toggle/component', ['exports', 'oath-ui-components/components/oa-toggle-base/component', 'oath-ui-components/components/oa-toggle/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    classNames: 'oa-toggle',
    attributeBindings: ['style'],
    icon: {
      on: 'toggle-right',
      off: 'toggle-left'
    },
    color: {
      on: '#98B36A',
      off: '#f76060'
    },
    label: {
      on: '',
      off: ''
    },
    tooltip: {
      on: '',
      off: ''
    },
    toggleIcon: Ember.computed('value', 'isHover', function () {
      const icon = this.get('icon');

      if (Ember.typeOf(icon) === 'string') {
        return icon;
      }

      if (this.get('isHover') && Ember.isPresent(icon.hover)) {
        if (this.get('value')) {
          return icon.hover.on;
        }
        return icon.hover.off;
      }

      if (this.get('value')) {
        return icon.on;
      }
      return icon.off;
    }),
    labelText: Ember.computed('value', 'label', function () {
      const label = this.get('label');
      if (Ember.typeOf(label) === 'string') {
        return label;
      }

      if (this.get('value')) {
        return label.on;
      }

      return label.off;
    }),
    tooltipText: Ember.computed('value', 'tooltip', function () {
      const tooltip = this.get('tooltip');

      if (Ember.typeOf(tooltip) === 'string') {
        return tooltip;
      }

      if (this.get('value')) {
        return tooltip.on;
      } // else
      return tooltip.off;
    }),
    style: Ember.computed('value', 'isHover', function () {
      const color = this.get('color');
      let styleString;

      if (Ember.typeOf(color) === 'string') {
        styleString = `color:${color}`;
      } else if (this.get('isHover') && Ember.isPresent(color.hover)) {
        if (this.get('value')) {
          styleString = `color:${color.hover.on}`;
        } else {
          styleString = `color:${color.hover.off}`;
        }
      } else if (this.get('value')) {
        styleString = `color:${color.on}`;
      } else {
        styleString = `color:${color.off}`;
      }

      return Ember.String.htmlSafe(styleString);
    })
  }).reopenClass({
    positionalParams: ['value', 'label', 'tooltip', 'icon', 'color', 'transiton']
  });
});