define('oath-ui-components/components/oa-grid/table/component', ['exports', 'oath-ui-components/components/oa-grid/table/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-grid__table'],
    page: 1,
    previousTopRow: 0,
    nextPageLoading: false,

    treeBrowserConfig: {
      hasChildren: () => false,
      getChildren: () => [],
      expandIcons: ['triangle-down', 'triangle-right']
    },

    actions: {
      onBasicEdit(value, item, column) {
        item.set(column.property, value);
      },
      hasChildren(parent) {
        return this.treeBrowserConfig.hasChildren(parent);
      },
      getChildren(parent) {
        return this.treeBrowserConfig.getChildren(parent);
      },
      checkThreshold([topRow]) {
        if (!this.get('properties')) {
          return;
        }

        const {
          pageSize,
          loadNextPage,
          threshold,
          hasNextPage
        } = this.get('properties');
        const modelLength = this.get('page') * pageSize;

        if (!this.get('nextPageLoading') && pageSize && loadNextPage && hasNextPage) {
          const defaultThreshold = threshold || modelLength / 2;
          const verticalCollectionBuffer = 6;

          if (topRow + verticalCollectionBuffer > defaultThreshold && defaultThreshold < modelLength) {
            this.set('nextPageLoading', true);
            loadNextPage(this.get('page') + 1).then(() => {
              this.set('page', this.get('page') + 1);
              this.set('nextPageLoading', false);
            });
          }
        }
      }
    }
  });
});