define('ember-microstates/utils/assign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = Ember.assign || function (...objects) {
    return objects.reduce(Ember.merge);
  };
});