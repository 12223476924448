define('oath-ui-components/components/oa-tooltip/component', ['exports', 'oath-ui-components/components/oa-reveal/component', 'oath-ui-components/components/oa-tooltip/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * A simple tooltip component.
   *
   * Usage:
   *
   * {{#oa-tooltip position=(hash my="left" at="right+5")}}
   *   This is the tooltip.
   * {{else}}
   *   This is the element to be triggered.
   * {{/oa-tooltip}}
   *
   * or
   *
   * {{#oa-tooltip "This is the tooltip." position=(hash my="top" at="bottom+5" }}
   *   This is the element to be triggered.
   * {{/oa-tooltip}}
   *
   * @class TooltipComponent
   * @module component
   */

  function getAlignment(element) {
    const ww = Math.max(document.documentElement.clientWidth, window.innerWidth || 0); // window width
    const wh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0); // height
    let verticalAlignment = 'top';
    let horizontalAlignment = 'right';

    if (element) {
      const pos = element.position(); // position of the hovered element relative to window

      if (pos.left > ww / 2) {
        // element is on right side of viewport
        horizontalAlignment = 'left';
      }

      if (pos.top > wh / 2) {
        verticalAlignment = 'bottom';
      }
    }

    return Object.freeze({
      horizontal_alignment: horizontalAlignment,
      vertical_alignment: verticalAlignment
    });
  }

  exports.default = _component.default.extend({
    classNames: 'oa-tooltip',
    layout: _template.default,

    autoHorizontal: false,
    autoVertical: false,

    /**
     * @attribute position
     */
    position: Ember.computed({
      get() {
        return Object.freeze({
          my: 'top',
          at: 'bottom+5'
        });
      },

      set(key, value) {
        if (typeof value === 'object') {
          return value;
        }

        const [side, align = 'center'] = value.split(/\s+/);

        if (side === 'top') {
          return Object.freeze({
            my: `bottom-12 ${align}`,
            at: `top ${align}`
          });
        }

        if (side === 'bottom') {
          return Object.freeze({
            my: `top+12 ${align}`,
            at: `bottom ${align}`
          });
        }

        if (side === 'left') {
          return Object.freeze({
            my: `right-12 ${align}`,
            at: `left ${align}`
          });
        }

        if (side === 'right') {
          return Object.freeze({
            my: `left+12 ${align}`,
            at: `right ${align}`
          });
        }

        return undefined;
      }
    }),

    didInsertElement(...args) {
      this._super(...args);
      if (this.get('autoHorizontal')) {
        const alignment = getAlignment(this.$());
        this.set('position', alignment.horizontal_alignment);
      } else if (this.get('autoVertical')) {
        const alignment = getAlignment(this.$());
        this.set('position', alignment.vertical_alignment);
      }
    },

    /**
     * @method
     * @private method
     */
    method: 'hover'
  });
});