define("ember-microstates/helpers/boolean", ["exports", "ember-microstates"], function (exports, _emberMicrostates) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const True = Object.create([true], {
    value: { value: true },
    valueOf: { value: () => {
        return true;
      } },
    toString: { value: () => "true" }
  });

  const False = Object.create([], {
    value: { value: false },
    valueOf: { value: () => {
        return false;
      } },
    toString: { value: () => "false" }
  });

  exports.default = _emberMicrostates.MicroState.extend({

    initialValueFor([value]) {
      return !!value;
    },

    prototypeFor(value) {
      return value ? True : False;
    },

    transitions: {
      toggle(current) {
        return !current;
      },
      set(current, value) {
        return !!value;
      }
    }
  });
});