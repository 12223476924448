define('oath-ui-components/components/oa-notifications/initializers/inject-notifications-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    ['component', 'controller', 'route'].forEach(factory => {
      application.inject(factory, 'notifications', 'service:notifications');
    });
  }

  exports.default = {
    name: 'inject-notifications-service',
    initialize
  };
});