define('oath-ui-components/components/oa-text/component', ['exports', 'oath-ui-components/components/oa-text/template', 'oath-ui-components/components/oa-input-base/component'], function (exports, _template, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: ['oa-text'],
    classNameBindings: ['isFocused:oa-text--focus', 'isBlurred:oa-text--blur', 'disabled:oa-text--disabled:oa-text--enabled'],
    attributeBindings: 'title',
    layout: _template.default,

    /**
     * @attribute placeholder
     */
    placeholder: null,

    /**
     * @attribute autocomplete
     */
    autocomplete: 'off',

    /**
     * @attribute autofocus
     */
    autofocus: false,

    /**
     * @attribute disabled
     */
    disabled: false,

    /**
     * @attribute numeric
     */
    numeric: false,

    /**
     * @protected
     * @property inputElement
     */
    inputElement: Ember.computed(function () {
      return this.element.querySelector('.oa-text__infix');
    }).readOnly(),

    numericEventHander(event) {
      // html5 type=number removed due to spin arrows...below code is sufficient

      // block relevent events base on
      // https://jsfiddle.net/Behseini/ue8gj52t/
      const target = Ember.$(event.target);

      const value = target.val().replace(/[^\d].+/, '');

      target.val(value);
      this.set('inputElement.value', value);
      this.set('value', value);

      this.sendEventAction('on-input');

      if (event.which < 48 || event.which > 57) {
        event.preventDefault();
        event.stopPropagation();

        return false;
      }

      return true;
    },

    didRender(...args) {
      this._super(...args);

      this.set('inputElement.value', this.get('value') || '');
      this.set('inputElement.autofocus', this.get('autofocus'));
      this.set('inputElement.disabled', this.get('disabled'));

      if (this.get('numeric')) {
        this.$('.oa-text__infix').on('keyup keypress blur', this.get('numericEventHander').bind(this));
      }
    },

    willDestroyElement() {
      this.$('.oa-text__infix').off('keyup keypress blur');
    },

    /**
     * @event on-input
     */
    input(...args) {
      this._super(...args);

      this.sendEventAction('on-input');
    },

    /**
     * @event on-enter-key
     */
    /**
     * @event on-escape-key
     */
    /**
     * @event on-backspace-key
     */
    keyUp(evt, ...args) {
      this._super(evt, ...args);

      switch (evt.key) {
        case 'Enter':
          this.sendEventAction('on-enter-key');break;
        case 'Escape':
          this.sendEventAction('on-escape-key');break;
        case 'Backspace':
          this.sendEventAction('on-backspace-key');break;
        default:
          break;
      }
    },

    sendEventAction(actionName) {
      return this._super(actionName, this.get('inputElement.value'));
    }
  });
});