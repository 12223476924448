define('oath-ui-components/components/oa-grid/inline-edit/component', ['exports', 'oath-ui-components/components/oa-grid/inline-edit/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    edit: false,
    value: '',
    commitDisabled: Ember.computed('value', 'columnValue', function () {
      const { value, columnValue } = this.getProperties('value', 'columnValue');
      return value === columnValue;
    }),
    columnValue: Ember.computed('item', 'column.property', {
      get() {
        const item = this.get('item');
        return item.get(this.get('column.property'));
      },
      set(_, value) {
        // cache clear
        return value;
      }
    }),
    actions: {
      clear() {
        this.set('value', '');
        this.set('edit', false);
      },
      commit() {
        const { value, item, column } = this.getProperties('value', 'item', 'column');
        if (this.get('onCommit')(value, item, column)) {
          this.set('edit', false);
          this.set('columnValue', value);
        }
      },
      edit() {
        this.set('value', this.get('columnValue'));
        this.set('edit', true);
      }
    }
  }).reopenClass({
    positionalParams: ['value']
  });
});