define('@ouroath/oath-ui-authentication/authenticators/one-central', ['exports', 'ember-simple-auth/authenticators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    location: Ember.computed(() => window.location),
    document: Ember.computed(() => window.document),
    restore(data) {
      return Ember.RSVP.Promise.resolve(data);
    },

    authenticate() {
      return this.doAuthentication();
    },

    invalidate() {
      return Ember.RSVP.Promise.resolve();
    },

    config: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment')['ember-one-central-authentication'];
    }),

    loginUrl(withPrompt) {
      const config = this.get('config');
      const redirectURI = config.tokenScraper;
      return `${config.identityHost}?redirect_uri=${encodeURIComponent(redirectURI)}&response_type=id_token+token\
&realm=${encodeURIComponent(config.realm)}&client_id=${encodeURIComponent(config.clientId)}\
&scope=${encodeURIComponent('openid email')}&prompt=${withPrompt ? 'login' : 'none'}&state=none&nonce=none`;
    },

    ensureAuthFrame() {
      if (!Ember.$('#authFrame').length) {
        Ember.$('body').append('<iframe id="authFrame" src="about:blank" target="_parent" style="display:none"></iframe>');
      }
    },

    setIframeUrl() {
      Ember.$('#authFrame')[0].contentWindow.location.replace(this.loginUrl(false));
    },

    messageListener(resolve, reject) {
      const messageReceiver = event => {
        try {
          const config = this.get('config');
          const arr = config.tokenScraper.split('/');
          const origin = `${arr[0]}//${arr[2]}`;

          if (typeof event.originalEvent.data === 'string' && origin === event.originalEvent.origin) {
            const data = JSON.parse(event.originalEvent.data);
            if (data.type === 'OC_TOKEN_RECEIVED') {
              resolve({ access_token: data.accessToken });
            } else {
              window.console.debug('Not authenticated, redirectiog to login page.');
              reject('OC_TOKEN_ERROR');
            }
          }
        } catch (err) {
          window.console.warn('One Central authentication module received not authentication related message, ignoring.');
          reject('OC_TOKEN_PARSE_ERROR');
        }
      };
      return messageReceiver;
    },

    doAuthentication() {
      window.console.debug('Verifying authentication.');
      return new Ember.RSVP.Promise((resolve, reject) => {
        Ember.$(window).on('message', this.messageListener(resolve, reject));
        this.ensureAuthFrame();
        this.setIframeUrl();
      }).then(data => {
        this.scheduleAuthenticationRefresh();
        return data;
      }).catch(reason => {
        // No token, let's login.
        if (reason === 'OC_TOKEN_ERROR') {
          this.startLogin();
        }
        throw reason;
      });
    },

    reauthenticate() {
      window.console.debug('Re-checking authentication.');
      Ember.run.cancel(this.get('refreshTimer'));
      this.doAuthentication().then(data => {
        this.trigger('sessionDataUpdated', data);
      });
    },

    scheduleAuthenticationRefresh() {
      const timer = Ember.run.later(() => {
        this.reauthenticate();
      }, this.get('config').refreshInterval);

      return this.set('refreshTimer', timer);
    },
    startLogin() {
      this.writeRedirectCookie();
      this.get('location').href = this.loginUrl(true);
    },
    writeRedirectCookie() {
      this.get('document').cookie = this.generateRedirectCookie();
    },
    generateRedirectCookie() {
      const config = this.get('config');
      const date = new Date();
      const redirectCookieName = config.redirectCookieName || 'onePostLoginRedirect';
      const redirectCookieDomain = config.redirectCookieDomain || '.aol.com';
      date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
      return `${redirectCookieName}={"url":"${window.location.href}"};domain=${redirectCookieDomain};path=/;expires=${date.toGMTString()}`;
    }
  });
});