define('oath-ui-components/components/oa-grid/inline-date-edit/component', ['exports', 'moment', 'oath-ui-components/components/oa-grid/inline-date-edit/template'], function (exports, _moment, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['oa-grid__inline-date-edit'],
    format: 'MM/DD/YYYY',

    actions: {
      onCommit(value, item, column) {
        if (!(value instanceof _moment.default) || !value.isValid()) {
          return false;
        }

        const format = this.get('format');
        item.set(column.get('property'), value.format(format));

        return true;
      }
    }
  });
});