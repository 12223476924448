define('oath-ui-components/components/oa-dropdown/content/group/component', ['exports', 'oath-ui-components/components/oa-dropdown/content/group/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default
  }).reopenClass({
    positionalParams: ['title']
  });
});